import { rbacFlags } from 'config/rbacFlags';
import { lazy } from 'react';
const ManagePools = lazy(() => import('pages/Esops/ManagePools/ManagePools'));
const ManagePlans = lazy(() => import('pages/Esops/ManagePlan/ManagePlans'));
const CreatePlan = lazy(() =>
  import('pages/Esops/ManagePlan/CreatePlan/CreatePlan'),
);
const ViewPlanData = lazy(() =>
  import('pages/Esops/ManagePlan/ViewPlan/ViewPlanData/ViewPlanData'),
);
const ManageGrantsHeader = lazy(() =>
  import('pages/Esops/grants/ManageGrantsHeader'),
);
const ManageUploadGrants = lazy(() =>
  import('pages/Esops/grants/ManageUploadGrants'),
);
const AdminDashboard = lazy(() =>
  import('pages/Esops/AdminDashboard/AdminDashboard'),
);
const PerformanceBasedRecognition = lazy(() =>
  import('pages/Esops/grants/PerformanceBasedRecognition'),
);
const ViewGrantDetails = lazy(() =>
  import('pages/Esops/grants/viewGrantDetails/ViewGrantDetails'),
);
const ManageTemplates = lazy(() =>
  import('pages/Esops/ManageTemplates/ManageTemplates'),
);
const CreateVestingTemplate = lazy(() =>
  import('pages/Esops/ManageTemplates/CreateVestingTemplate'),
);
const CreateExerciseTemplate = lazy(() =>
  import('pages/Esops/ManageTemplates/CreateExerciseTemplate'),
);
const ViewStakeholderDetails = lazy(() =>
  import(
    'pages/Esops/grants/stakeholder/ViewStakeholderDetails/ViewStakeholderDetails'
  ),
);
const ReviewGrantDetails = lazy(() =>
  import(
    'pages/Esops/grants/stakeholder/ViewStakeholderDetails/ReviewGrantDetails'
  ),
);
const EmployeeEventForm = lazy(() =>
  import(
    'pages/Esops/grants/stakeholder/ViewStakeholderDetails/EmployeeEventForm'
  ),
);
const GrantApproval = lazy(() =>
  import('pages/Esops/grants/grantApproval/GrantApproval'),
);
const ManageStakeholderAdmin = lazy(() =>
  import('pages/Esops/Stakeholder/ManageStakeholderAdmin'),
);
const AddAndEditScreen = lazy(() =>
  import('pages/Esops/Stakeholder/AddAndEditScreen'),
);
const ManageEvents = lazy(() =>
  import('pages/Esops/ManageEvents/ManageEvents'),
);
const CreateStep2 = lazy(() =>
  import('pages/Esops/ManageEvents/CreateSteps/CreateStep2'),
);
const ViewEvent = lazy(() =>
  import('pages/Esops/ManageEvents/ViewEvent/ViewEvent'),
);

const EsopsRoutes = [
  {
    type: 'collapse',
    name: 'managePools',
    key: 'managePools',
    parentId: 'Esops',
    route: '/esops/ManagePools',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <ManagePools />,
  },
  {
    type: 'collapse',
    name: 'managePlans',
    key: 'managePlans',
    parentId: 'Esops',
    route: '/esops/ManagePlans',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <ManagePlans />,
  },
  {
    type: 'collapse',
    name: 'esopCreatePlan',
    key: 'esopCreatePlan',
    parentId: 'Esops',
    route: '/esops/createPlan',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <CreatePlan />,
  },
  {
    type: 'collapse',
    name: 'esopCreatePlan',
    key: 'esopCreatePlan',
    parentId: 'Esops',
    route: '/esops/editPlan/:id',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <CreatePlan />,
  },
  {
    type: 'collapse',
    name: 'esopViewPlanData',
    key: 'esopViewPlanData',
    parentId: 'Esops',
    route: '/esops/viewPlan',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <ViewPlanData />,
  },
  {
    type: 'collapse',
    name: 'esopsAdminDashboard',
    key: 'esopsAdminDashboard',
    parentId: 'Esops',
    route: '/esops/adminDashboard',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <AdminDashboard />,
  },
  {
    type: 'collapse',
    name: 'EsopsGrants',
    key: 'EsopsGrants',
    parentId: 'Esops',
    route: '/esops/grants',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <ManageGrantsHeader />,
  },
  {
    type: 'collapse',
    name: 'ManageUploadGrants',
    key: 'manageUploadGrants',
    parentId: 'Esops',
    route: '/esops/grants/upload-data/:id',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <ManageUploadGrants />,
  },
  {
    type: 'collapse',
    name: 'GrantsPerformanceBasedRecognition',
    key: 'grantsPerformanceBasedRecognition',
    parentId: 'Esops',
    route: '/esops/grants/performance-based-recognition',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <PerformanceBasedRecognition />,
  },
  {
    type: 'collapse',
    name: 'viewGrantsDetails',
    key: 'viewGrantsDetails',
    parentId: 'Esops',
    route: '/esops/grants/viewDetails',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <ViewGrantDetails />,
  },
  {
    type: 'collapse',
    name: 'manageTemplates',
    key: 'manageTemplates',
    parentId: 'Esops',
    route: '/esops/ManageTemplates',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <ManageTemplates />,
  },
  {
    type: 'collapse',
    name: 'createVestingTemplates',
    key: 'createVestingTemplates',
    parentId: 'Esops',
    route: '/esops/templates/vesting/create',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <CreateVestingTemplate />,
  },
  {
    type: 'collapse',
    name: 'createExerciseTemplates',
    key: 'createExerciseTemplates',
    parentId: 'Esops',
    route: '/esops/templates/exercise/create',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <CreateExerciseTemplate />,
  },
  {
    type: 'collapse',
    name: 'viewStakeholderDetails',
    key: 'viewStakeholderDetails',
    parentId: 'Esops',
    route: '/esops/stakeholder/details',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <ViewStakeholderDetails />,
  },
  {
    type: 'collapse',
    name: 'reviewGrantDetails',
    key: 'reviewGrantDetails',
    parentId: 'Esops',
    route: '/esops/stakeholder/details/review-grant',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <ReviewGrantDetails />,
  },
  {
    type: 'collapse',
    name: 'employeeEventForm',
    key: 'employeeEventForm',
    parentId: 'Esops',
    route: '/esops/stakeholder/details/employee-event-form',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <EmployeeEventForm />,
  },
  {
    type: 'collapse',
    name: 'grantApproval',
    key: 'grantApproval',
    parentId: 'Esops',
    route: '/esops/grant/approval',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <GrantApproval />,
  },
  {
    type: 'collapse',
    name: 'manageStakeholders',
    key: 'manageStakeholders',
    parentId: 'Esops',
    route: '/esops/manageStakeholders',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <ManageStakeholderAdmin />,
  },
  {
    type: 'collapse',
    name: 'addStakeholder',
    key: 'addStakeholder',
    parentId: 'Esops',
    route: '/esops/manageStakeholders/edit/:type',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <AddAndEditScreen />,
  },
  {
    type: 'collapse',
    name: 'manageEvents',
    key: 'manageEvents',
    parentId: 'Esops',
    route: '/esops/ManageEvents',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <ManageEvents />,
  },
  {
    type: 'collapse',
    name: 'createEvent',
    key: 'createEvent',
    parentId: 'Esops',
    route: '/esops/createEvent',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <CreateStep2 />,
  },
  {
    type: 'collapse',
    name: 'viewEvent',
    key: 'viewEvent',
    parentId: 'Esops',
    route: '/esops/viewEvent/:id',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <ViewEvent />,
  },
];

export default EsopsRoutes;
