import Tabs from 'components/molecules/tabs/Tabs';
import { planSetupTabs } from 'config/variablePay';
import { Dispatch, SetStateAction } from 'react';
import { EventsSettings } from '../VariablePay.types';
import { EligibleGroup } from './EligibleGroup';
import { Settings } from './settings/Settings';

type PlanSetUpProps = {
  eligibleUserData: { [key: string]: any };
  setEligibleUserData: Dispatch<SetStateAction<{ [key: string]: any }>>;
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  setEventSettings: Dispatch<SetStateAction<EventsSettings>>;
  eventSettings: EventsSettings;
  setTableData: Dispatch<SetStateAction<any>>;
  tableData: any;
};

export const PlanSetUp = ({
  eligibleUserData,
  setEligibleUserData,
  setSelectedTab,
  selectedTab,
  setEventSettings,
  eventSettings,
  tableData,
  setTableData,
}: PlanSetUpProps) => {
  return (
    <div>
      <div className="px-8">
        <Tabs
          tabs={planSetupTabs}
          className="text-sm font-semibold text-gray-600"
          selectedTab={selectedTab}
          handleTab={(e: { tabLabel: string }) => setSelectedTab(e.tabLabel)}
        />
      </div>
      <div className="bg-gray-50">
        {selectedTab === 'Eligible Group' && (
          <EligibleGroup
            setEligibilityPayload={setEligibleUserData}
            eligibilityPayload={eligibleUserData}
          />
        )}
        {selectedTab === 'Settings' && (
          <Settings
            eventSettings={eventSettings}
            setEventSettings={setEventSettings}
            setTableData={setTableData}
            tableData={tableData}
          />
        )}
      </div>
    </div>
  );
};
