import {
  getAllInputFields,
  getInputFieldValues,
} from 'apiClient/compensation/compensation';
import { useMutation, useQuery } from 'react-query';

export const useGetAllEmployeeFields = () =>
  useQuery(['get/employeeFieldsEligibilityLimit'], () => getAllInputFields());
export const useGetAllInputFieldsValue = () =>
  useMutation(['get/employeeFieldInputValues'], (id) =>
    getInputFieldValues(id),
  );
