import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { getAllPlans } from 'apiClient/variablePay/variablePay';
import VerticalEclipse from 'assets/svg/verticalEclipse';
import Button from 'components/atoms/button/Button';
import ChipsLabels from 'components/atoms/chips-labels/ChipsLabels';
import Pagination from 'components/atoms/pagination/Pagination';
import TableSkeleton from 'components/atoms/skeletonLoaderTypes/TableSkeleton';
import Table from 'components/atoms/table/Table';
import TableHeader from 'components/atoms/tableHeader/TableHeader';
import { tabs } from 'pages/benefits/constants';
import { formatDate } from 'pages/recognition/orderHistory/helper';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BonusBasedOnEnum } from '../VariablePay.types';
import { VariablePaySubComponent } from './VariablePaySubComponent';

export const ManageVariablePayPlans = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].tabLabel);
  const [allPlans, setAllPlans] = useState([]);
  const [totalPlans, setTotalPlans] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const columns = [
    {
      id: '1',
      Header: <TableHeader name="Plan Name" HeaderKey="planName" sort={true} />,
      Cell: ({ row }: any) => {
        const [planIdOpen, setPlanIdOpen] = useState<string>('');
        const [planDetailsOpen, setPlanDetailsOpen] = useState<boolean>(false);
        const rowProps = row?.getToggleRowExpandedProps();
        return (
          <div className="flex items-center gap-3">
            {planDetailsOpen && planIdOpen === row.original?.id ? (
              <ChevronDownIcon
                onClick={() => {
                  rowProps.onClick();
                  setPlanDetailsOpen(false);
                  setPlanIdOpen('');
                }}
                className="w-4 h-4 text-gray-600 cursor-pointer"
              />
            ) : (
              <ChevronRightIcon
                onClick={() => {
                  rowProps.onClick();
                  setPlanDetailsOpen(true);
                  setPlanIdOpen(row.original?.id);
                }}
                className="w-4 h-4 text-gray-600 cursor-pointer"
              />
            )}

            <div>
              <p
                className="text-sm font-medium text-gray-900 cursor-pointer hover:underline"
                onClick={() =>
                  navigate(`/variable-pay/plan/${row.original?.id}`, {
                    state: { ...row.original },
                  })
                }
              >
                {row.original?.planName}
              </p>
              <p className="text-sm text-gray-600">{`ID ${row.original?.planId}`}</p>
            </div>
          </div>
        );
      },
    },
    {
      id: '2',
      Header: <TableHeader name="Type" HeaderKey="type" sort={true} />,
      Cell: ({ row }: any) => {
        return (
          <p className="text-sm text-gray-600">
            {`${
              row.original?.bonusBased === BonusBasedOnEnum.Events
                ? 'Event'
                : 'Metric'
            } Based`}
          </p>
        );
      },
    },
    {
      id: '3',
      Header: (
        <TableHeader
          name="Payout Frequency"
          HeaderKey="payoutFrequency"
          sort={true}
        />
      ),
      Cell: ({ row }: any) => {
        return (
          <p className="text-sm text-gray-600">
            {row.original?.payoutFrequency}
          </p>
        );
      },
    },
    {
      id: '4',
      Header: <TableHeader name="Status" HeaderKey="status" sort={true} />,
      Cell: ({ row }: any) => {
        return (
          <ChipsLabels
            // @ts-ignore
            className="text-sm font-medium"
            label={row.original?.status}
            status={row.original?.status === 'Active' ? 'accepted' : ''}
          />
        );
      },
    },
    {
      id: '5',
      Header: (
        <TableHeader
          name="Last Modified"
          HeaderKey="lastModified"
          sort={true}
        />
      ),
      Cell: ({ row }: any) => {
        return (
          <p className="text-sm text-gray-600">
            {formatDate(new Date(row.original?.updatedDate))}
          </p>
        );
      },
    },
    {
      id: '6',
      Header: (
        <TableHeader
          name="Plan Start Date"
          HeaderKey="planStartDate"
          sort={true}
        />
      ),
      Cell: ({ row }: any) => {
        return (
          <p className="text-sm text-gray-600">
            {formatDate(row.original?.startDate)}
          </p>
        );
      },
    },
    {
      id: '7',
      Header: (
        <TableHeader name="Plan End Date" HeaderKey="planEndDate" sort={true} />
      ),
      Cell: ({ row }: any) => {
        return (
          <p className="text-sm text-gray-600">
            {formatDate(row.original?.endDate)}
          </p>
        );
      },
    },
    {
      id: '8',
      width: '3%',
      Header: <TableHeader name="" HeaderKey="" sort={true} />,
      Cell: ({ row }: any) => {
        return <VerticalEclipse />;
      },
    },
  ];

  const handlePageClick = async (event: any) => {
    setCurrentPage(event.selected);
  };

  const renderRowSubComponent = useCallback(({ row }: any) => {
    return <VariablePaySubComponent data={[row.original]} />;
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await getAllPlans(currentPage);
        setAllPlans(response?.plans);
        setTotalPlans(response?.count);
        setLoading(false);
      } catch (error) {
        setAllPlans([]);
        setTotalPlans(0);
        setLoading(false);
      }
    })();
  }, [currentPage]);

  return (
    <div className="space-y-3">
      <div className="flex items-center gap-2">
        {tabs.map((tab) => {
          return (
            <Button
              key={tab.id}
              variant={selectedTab === tab.tabLabel ? 'outlined' : ''}
              onClick={() => setSelectedTab(tab.tabLabel)}
              className="font-semibold text-gray-600"
            >
              {tab.tabLabel}
            </Button>
          );
        })}
      </div>
      {loading ? (
        <TableSkeleton count={5} />
      ) : (
        <Table
          headerBackgroundColor="bg-gray-50"
          data={allPlans}
          columns={columns}
          isExpandable={true}
          renderRowSubComponent={renderRowSubComponent}
        />
      )}
      {totalPlans > 0 && !loading && (
        <div className="flex justify-center items-center my-4">
          <Pagination
            onPageChanged={handlePageClick}
            pageCount={Math.ceil(totalPlans / 5)}
            currentPage={currentPage}
          />
        </div>
      )}
    </div>
  );
};
