import {
  PlusIcon,
  PlusSmallIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import DeleteFormIcon from 'assets/svg/deleteFormIcon';
import DatePicker from 'components/atoms/FormElements/datepicker/DatePicker';
import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import Table from 'components/atoms/table/Table';
import TableHeader from 'components/atoms/tableHeader/TableHeader';
import FormulaBuilder from 'components/molecules/formulaBuilder/FormulaBuilder';
import {
  AttainmentInputsEnum,
  EventsSettings,
  PayoutAmountsEnum,
  RangeOrSpecificValueEnum,
  SlabsInclusionEnum,
  UserGroupConditionSpecificityEnum,
  UserGroupPayoutSepecificityEnum,
} from 'pages/variablePay/VariablePay.types';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { v4 } from 'uuid';

type PayoutCalculationProps = {
  eventSettings: EventsSettings;
  setTableData: Dispatch<SetStateAction<any>>;
  tableData: any;
};

const userGroupDropDown = [
  {
    label: 'User Group 1',
    value: 'User Group 1',
  },
  {
    label: 'User Group 2',
    value: 'User Group 2',
  },
  {
    label: 'User Group 3',
    value: 'User Group 3',
  },
  {
    label: 'User Group 4',
    value: 'User Group 4',
  },
];

const formulaDetails = {
  formulaString: '',
  displayFormula: '',
  dependencyValueMap: {},
  dependencyChainMap: {},
};

export const PayoutCalculation = ({
  eventSettings,
  setTableData,
  tableData,
}: PayoutCalculationProps) => {
  //   const [tableData, setTableData] = useState<any>([[{ id: v4() }]]);

  const handleKeyPress = (e: any) => {
    if (e.key === '-' || e.key === '+') {
      e.preventDefault();
    }
  };

  const addRowToGroup = (groupIndex: number) => {
    setTableData((prevData: any) =>
      prevData.map((group: any, index: number) =>
        index === groupIndex ? [...group, { id: v4() }] : group,
      ),
    );
  };

  const removeRowFromGroup = (groupIndex: number, rowId: string) => {
    setTableData((prevData: any) =>
      prevData.map((group: any, index: number) =>
        index === groupIndex
          ? group.filter((row: any) => row.id !== rowId)
          : group,
      ),
    );
  };

  const userGroupField = (id: string) => ({
    id,
    width: '22%',
    Header: (
      <TableHeader
        name={
          <div className="flex items-center gap-2">
            <Select
              //@ts-ignore
              options={userGroupDropDown}
              placeholder="Select user group"
              className="w-full grow"
              onChange={(option: any) =>
                setUserGroupColumns((prevColumns: any) =>
                  prevColumns?.map((column: any) =>
                    column?.id === id
                      ? { ...column, userGroupValue: option?.value }
                      : column,
                  ),
                )
              }
            />
            <button
              className={`w-10 h-10 border border-gray-300 rounded-lg p-2 flex items-center justify-center cursor-pointer`}
              onClick={() =>
                setUserGroupColumns((prevColumns: any) =>
                  prevColumns.filter((column: any) => column?.id !== id),
                )
              }
            >
              <TrashIcon className="text-gray-500 w-5 h-5" />
            </button>
          </div>
        }
      />
    ),
  });

  const ifField = (id: string, groupIndex: number) => ({
    id,
    Header: <TableHeader name="If" HeaderKey="if" />,
    Cell: ({ row }: any) => {
      return (
        <>
          {eventSettings?.vPayAttainmentInput?.value ===
            AttainmentInputsEnum.PERCENTAGE ||
          eventSettings?.vPayAttainmentInput?.value ===
            AttainmentInputsEnum.DAYS ||
          eventSettings?.vPayAttainmentInput?.value ===
            AttainmentInputsEnum.TEXT ? (
            <Input
              // @ts-ignore
              placeholder="Enter value"
              type={`${
                eventSettings?.vPayAttainmentInput?.value ===
                AttainmentInputsEnum.TEXT
                  ? 'text'
                  : 'number'
              }`}
              onKeyPress={handleKeyPress}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange(
                  groupIndex,
                  row.original.id,
                  `${id}_if`,
                  e.target.value,
                )
              }
              value={row.original?.[`${id}_if`]}
            />
          ) : (
            <DatePicker />
          )}
        </>
      );
    },
  });

  const fromField = (id: string, groupIndex: number) => ({
    id,
    Header: <TableHeader name="From" HeaderKey="from" />,
    Cell: ({ row }: any) => {
      return (
        <>
          {eventSettings?.vPayAttainmentInput?.value ===
            AttainmentInputsEnum.PERCENTAGE ||
          eventSettings?.vPayAttainmentInput?.value ===
            AttainmentInputsEnum.DAYS ||
          eventSettings?.vPayAttainmentInput?.value ===
            AttainmentInputsEnum.TEXT ? (
            <Input
              // @ts-ignore
              placeholder="Enter value"
              type={`${
                eventSettings?.vPayAttainmentInput?.value ===
                AttainmentInputsEnum.TEXT
                  ? 'text'
                  : 'number'
              }`}
              onKeyPress={handleKeyPress}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange(
                  groupIndex,
                  row.original.id,
                  `${id}_from`,
                  e.target.value,
                )
              }
              value={row.original?.[`${id}_from`]}
            />
          ) : (
            <DatePicker />
          )}
        </>
      );
    },
  });

  const uptoField = (id: string, groupIndex: number) => ({
    id,
    Header: <TableHeader name="Upto" HeaderKey="upto" />,
    Cell: ({ row }: any) => {
      return (
        <>
          {eventSettings?.vPayAttainmentInput?.value ===
            AttainmentInputsEnum.PERCENTAGE ||
          eventSettings?.vPayAttainmentInput?.value ===
            AttainmentInputsEnum.DAYS ||
          eventSettings?.vPayAttainmentInput?.value ===
            AttainmentInputsEnum.TEXT ? (
            <Input
              // @ts-ignore
              type={`${
                eventSettings?.vPayAttainmentInput?.value ===
                AttainmentInputsEnum.TEXT
                  ? 'text'
                  : 'number'
              }`}
              placeholder="Enter value"
              onKeyPress={handleKeyPress}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange(
                  groupIndex,
                  row.original.id,
                  `${id}_upto`,
                  e.target.value,
                )
              }
              value={row.original?.[`${id}_upto`]}
            />
          ) : (
            <DatePicker />
          )}
        </>
      );
    },
  });

  const deleteField = (id: string, groupIndex: number) => ({
    id,
    width: '3%',
    Cell: ({ row }: any) => {
      return (
        <DeleteFormIcon
          className={`${'cursor-pointer'}`}
          onClick={() =>
            tableData?.[0]?.length > 1 &&
            removeRowFromGroup(groupIndex, row.original.id)
          }
        />
      );
    },
  });

  const handleChange = (
    groupIndex: number,
    id: string,
    field: string,
    value: string,
  ) => {
    setTableData((prevData: any) =>
      prevData.map((group: any, index: number) =>
        index === groupIndex
          ? group.map((row: any) =>
              row.id === id ? { ...row, [field]: value } : row,
            )
          : group,
      ),
    );
  };

  const payoutField = (id: string, groupIndex: number) => ({
    id,
    Header: <TableHeader name="Payout" HeaderKey="payout" sort={false} />,
    Cell: ({ row }: any) => {
      const [formulaBuilderVisible, setFormulaBuilderVisible] = useState(false);

      return (
        <>
          {!formulaBuilderVisible ? (
            <Input
              // @ts-ignore
              placeholder="Enter Value or Formual"
              onClick={() => setFormulaBuilderVisible(true)}
              value={row.original?.[`${id}_payout`]?.displayFormula}
            />
          ) : (
            <FormulaBuilder
              setModel={setFormulaBuilderVisible}
              saveAction={(formula: any) =>
                handleChange(
                  groupIndex,
                  row.original?.id,
                  `${id}_payout`,
                  formula,
                )
              }
              formulaDetails={
                row.original?.[`${id}_payout`]?.displayFormula
                  ? row.original?.[`${id}_payout`]
                  : formulaDetails
              }
              moduleName={'Compensation'}
              sourceId={undefined}
              selectedColumn={undefined}
            />
          )}
        </>
      );
    },
  });

  const [displayColumns, setDisplayColumns] = useState<any>([]);
  const [userGroupColumns, setUserGroupColumns] = useState<any>([]);

  const initialUserGroupColumns = useRef<any>([
    userGroupField(v4()),
    { id: v4() },
  ]);

  useEffect(() => {
    setUserGroupColumns(initialUserGroupColumns.current); // Set the initial columns once
  }, []);

  useEffect(() => {
    if (
      eventSettings?.vPayUGConditionSpecificity?.value ===
        UserGroupConditionSpecificityEnum.DIFFERS_BY_GROUP ||
      eventSettings?.vPayUGPayoutSpecificity?.value ===
        UserGroupPayoutSepecificityEnum.DIFFERS_PAYOUT_BY_GROUP
    ) {
      setUserGroupColumns([
        userGroupField(v4()),
        {
          id: v4(),
        },
      ]);
    }
  }, [eventSettings]);

  useEffect(() => {
    if (
      eventSettings?.vPayPayoutAmounts?.value ===
      PayoutAmountsEnum.LOGIC_BASED_CALCULATION
    ) {
      if (
        eventSettings?.vPayUGConditionSpecificity?.value ===
          UserGroupConditionSpecificityEnum.DIFFERS_BY_GROUP &&
        eventSettings?.vPayUGPayoutSpecificity?.value ===
          UserGroupPayoutSepecificityEnum.DIFFERS_PAYOUT_BY_GROUP
      ) {
        if (
          eventSettings?.vPayRangeSpecificity?.value ===
          RangeOrSpecificValueEnum.SPECIFIC_VALUE
        ) {
          eventSettings?.vPaySlabsInclusion?.value ===
          SlabsInclusionEnum.NO_SLABS
            ? setDisplayColumns([ifField(v4(), 0), payoutField(v4(), 0)])
            : setDisplayColumns([
                ifField(v4(), 0),
                payoutField(v4(), 0),
                deleteField(v4(), 0),
              ]);
        } else if (
          eventSettings?.vPayRangeSpecificity?.value ===
          RangeOrSpecificValueEnum.RANGE_VALUE
        ) {
          eventSettings?.vPaySlabsInclusion?.value ===
          SlabsInclusionEnum.NO_SLABS
            ? setDisplayColumns([
                fromField(v4(), 0),
                uptoField(v4(), 0),
                payoutField(v4(), 0),
              ])
            : setDisplayColumns([
                fromField(v4(), 0),
                uptoField(v4(), 0),
                payoutField(v4(), 0),
                deleteField(v4(), 0),
              ]);
        }
      } else {
        if (
          eventSettings?.vPayRangeSpecificity?.value ===
          RangeOrSpecificValueEnum.SPECIFIC_VALUE
        ) {
          eventSettings?.vPaySlabsInclusion?.value ===
          SlabsInclusionEnum.NO_SLABS
            ? setDisplayColumns([
                ...(eventSettings?.vPayUGConditionSpecificity?.value ===
                UserGroupConditionSpecificityEnum.SAME_FOR_ALL
                  ? [ifField(v4(), 0)]
                  : (
                      userGroupColumns.slice(0, userGroupColumns?.length - 1) ||
                      []
                    ).map((column: any, index: number) =>
                      ifField(`${column?.id}_${v4()}`, index),
                    )),
                ...(eventSettings?.vPayUGPayoutSpecificity?.value ===
                UserGroupPayoutSepecificityEnum.SAME_PAYOUT_FOR_ALL
                  ? [payoutField(v4(), 0)]
                  : (
                      userGroupColumns.slice(0, userGroupColumns?.length - 1) ||
                      []
                    ).map((column: any, index: number) =>
                      payoutField(`${column?.id}_${v4()}`, index),
                    )),
              ])
            : setDisplayColumns([
                ...(eventSettings?.vPayUGConditionSpecificity?.value ===
                UserGroupConditionSpecificityEnum.SAME_FOR_ALL
                  ? [ifField(v4(), 0)]
                  : (
                      userGroupColumns.slice(0, userGroupColumns?.length - 1) ||
                      []
                    ).map((column: any, index: number) =>
                      ifField(`${column?.id}_${v4()}`, index),
                    )),
                ...(eventSettings?.vPayUGPayoutSpecificity?.value ===
                UserGroupPayoutSepecificityEnum.SAME_PAYOUT_FOR_ALL
                  ? [payoutField(v4(), 0)]
                  : (
                      userGroupColumns.slice(0, userGroupColumns?.length - 1) ||
                      []
                    ).map((column: any, index: number) =>
                      payoutField(`${column?.id}_${v4()}`, index),
                    )),
                deleteField(v4(), 0),
              ]);
        } else if (
          eventSettings?.vPayRangeSpecificity?.value ===
          RangeOrSpecificValueEnum.RANGE_VALUE
        ) {
          eventSettings?.vPaySlabsInclusion?.value ===
          SlabsInclusionEnum.NO_SLABS
            ? setDisplayColumns([
                ...(eventSettings?.vPayUGConditionSpecificity?.value ===
                UserGroupConditionSpecificityEnum.SAME_FOR_ALL
                  ? [fromField(v4(), 0), uptoField(v4(), 0)]
                  : (
                      userGroupColumns.slice(0, userGroupColumns?.length - 1) ||
                      []
                    )
                      .map((column: any, index: number) => [
                        fromField(`${column?.id}_${v4()}`, index),
                        uptoField(`${column?.id}_${v4()}`, index),
                      ])
                      .flat()),
                ...(eventSettings?.vPayUGPayoutSpecificity?.value ===
                UserGroupPayoutSepecificityEnum.SAME_PAYOUT_FOR_ALL
                  ? [payoutField(v4(), 0)]
                  : (
                      userGroupColumns.slice(0, userGroupColumns?.length - 1) ||
                      []
                    ).map((column: any, index: number) =>
                      payoutField(`${column?.id}_${v4()}`, index),
                    )),
              ])
            : setDisplayColumns([
                ...(eventSettings?.vPayUGConditionSpecificity?.value ===
                UserGroupConditionSpecificityEnum.SAME_FOR_ALL
                  ? [fromField(v4(), 0), uptoField(v4(), 0)]
                  : (
                      userGroupColumns.slice(0, userGroupColumns?.length - 1) ||
                      []
                    )
                      .map((column: any, index: number) => [
                        fromField(`${column?.id}_${v4()}`, index),
                        uptoField(`${column?.id}_${v4()}`, index),
                      ])
                      .flat()),
                ...(eventSettings?.vPayUGPayoutSpecificity?.value ===
                UserGroupPayoutSepecificityEnum.SAME_PAYOUT_FOR_ALL
                  ? [payoutField(v4(), 0)]
                  : (
                      userGroupColumns.slice(0, userGroupColumns?.length - 1) ||
                      []
                    ).map((column: any, index: number) =>
                      payoutField(`${column?.id}_${v4()}`, index),
                    )),
                deleteField(v4(), 0),
              ]);
        }
      }
    }
  }, [eventSettings, userGroupColumns]);

  const addNewUserGroup = () => {
    setUserGroupColumns([
      ...userGroupColumns.slice(0, userGroupColumns.length - 1),
      userGroupField(v4()),
      ...userGroupColumns.slice(userGroupColumns?.length - 1),
    ]);
  };

  return (
    <div className="border border-gray-200 rounded-xl bg-white p-6 shadow-md space-y-6">
      <div className="flex items-center justify-between">
        <p className="font-semibold text-gray-900">Payout Calculation</p>
        {(eventSettings?.vPayUGConditionSpecificity?.value ===
          UserGroupConditionSpecificityEnum.DIFFERS_BY_GROUP ||
          eventSettings?.vPayUGPayoutSpecificity?.value ===
            UserGroupPayoutSepecificityEnum.DIFFERS_PAYOUT_BY_GROUP) && (
          <button
            className="flex items-center gap-2 text-gray-600 font-semibold text-sm cursor-pointer"
            onClick={addNewUserGroup}
          >
            <PlusSmallIcon className="text-gray-600 w-5 h-5" /> Add User Group
          </button>
        )}
      </div>
      <div className="space-y-2">
        {eventSettings?.vPayUGConditionSpecificity?.value ===
          UserGroupConditionSpecificityEnum.DIFFERS_BY_GROUP &&
        eventSettings?.vPayUGPayoutSpecificity?.value ===
          UserGroupPayoutSepecificityEnum.DIFFERS_PAYOUT_BY_GROUP ? (
          <>
            {userGroupColumns
              .slice(0, userGroupColumns?.length - 1)
              ?.map((groupColumn: any, groupIndex: number) => {
                return (
                  <div key={groupColumn?.id}>
                    <div>
                      <div className="-mb-10">
                        <Table
                          data={[]}
                          columns={[
                            userGroupColumns?.[groupIndex],
                            userGroupColumns?.[userGroupColumns?.length - 1],
                          ]}
                        />
                      </div>
                      <div className="-mb-8">
                        <Table
                          data={tableData[groupIndex]}
                          columns={displayColumns}
                        />
                      </div>
                    </div>
                    {eventSettings?.vPaySlabsInclusion?.value ===
                      SlabsInclusionEnum.INCLUDES_SLABS && (
                      <button
                        className="flex items-center justify-center text-sm font-medium text-gray-600 gap-2"
                        onClick={() => addRowToGroup(groupIndex)}
                      >
                        Add rule <PlusIcon className="text-gray-600 w-4 h-4" />
                      </button>
                    )}
                  </div>
                );
              })}
          </>
        ) : (
          <>
            <div>
              {(eventSettings?.vPayUGConditionSpecificity?.value ===
                UserGroupConditionSpecificityEnum.DIFFERS_BY_GROUP ||
                eventSettings?.vPayUGPayoutSpecificity?.value ===
                  UserGroupPayoutSepecificityEnum.DIFFERS_PAYOUT_BY_GROUP) && (
                <div className="-mb-10">
                  <Table data={[]} columns={userGroupColumns} />
                </div>
              )}
              <div className="-mb-8">
                <Table data={tableData[0]} columns={displayColumns} />
              </div>
            </div>
            {eventSettings?.vPaySlabsInclusion?.value ===
              SlabsInclusionEnum.INCLUDES_SLABS && (
              <button
                className="flex items-center justify-center text-sm font-medium text-gray-600 gap-2"
                onClick={() => addRowToGroup(0)}
              >
                Add rule <PlusIcon className="text-gray-600 w-4 h-4" />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};
