import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { getInputFieldCriteria } from 'apiClient/compensation/compensation';
import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import FormulaBuilder from 'components/molecules/formulaBuilder/FormulaBuilder';
import { EventsSettings } from 'pages/variablePay/VariablePay.types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { v4 } from 'uuid';

type DependencyProps = {
  setEventSettings: Dispatch<SetStateAction<EventsSettings>>;
  eventSettings: EventsSettings;
};

export const Dependency = ({
  eventSettings,
  setEventSettings,
}: DependencyProps) => {
  const [allOperators, setAllOperators] = useState([]);
  const [formulaBuilderVisible, setFormulaBuilderVisible] = useState(false);
  const formulaDetails = {
    formulaString: '',
    displayFormula: '',
    dependencyValueMap: {},
    dependencyChainMap: {},
  };
  const [conditions, setConditions] = useState<any>([
    { id: v4(), formulaDetails },
  ]);
  const [conditionType, setConditionType] = useState('AND');

  const fetchOperators = () => {
    getInputFieldCriteria('datatype', 'All?map=1')
      .then((res) => {
        setAllOperators(
          res?.Amount?.map((item: any) => ({
            label: item?.displayName,
            value: item?.code,
          })),
        );
      })
      .catch((err) => {
        setAllOperators([]);
      });
  };

  useEffect(() => {
    fetchOperators();
  }, []);

  const handleChange = (key: string, value: any, id: string) => {
    setConditions(
      conditions?.map((condition: any) => {
        if (condition?.id === id) {
          return { ...condition, [key]: value };
        }
        return condition;
      }),
    );
  };

  useEffect(() => {
    setEventSettings({
      ...eventSettings,
      vPayDependencyCriteria: { value: { [conditionType]: conditions } },
    });
  }, [conditions]);

  return (
    <div className="space-y-4">
      <div className="bg-gray-50 p-4 rounded-lg">
        <div className="border bg-gray-100 p-4 mt-2  rounded-lg">
          <div className="flex justify-between items-center">
            <div className="flex ">
              <div
                className={`${
                  conditionType === 'AND' ? 'bg-white' : ''
                } border-2 border-gray-300  rounded-l-md p-2 text-sm font-semibold px-4 cursor-pointer`}
                onClick={() => setConditionType('AND')}
              >
                And
              </div>
              <div
                className={`${
                  conditionType === 'OR' ? 'bg-white' : ''
                } border-2 border-gray-300 p-2 border-l-0  rounded-r-md text-sm font-semibold px-4 cursor-pointer`}
                onClick={() => setConditionType('OR')}
              >
                Or
              </div>
            </div>
            <div
              className="font-semibold  text-sm cursor-pointer"
              onClick={() => setConditions([{ id: v4(), formulaDetails }])}
            >
              Clear All
            </div>
          </div>

          {conditions?.map((condition: any, index: number) => {
            return (
              <div
                key={index}
                className=" flex justify-between items-center w-full"
              >
                <div className=" w-[95%] grid grid-cols-4 gap-2  items-center">
                  <div>
                    <Select
                      // @ts-ignore
                      options={eventSettings?.vPayDependentFields?.[
                        'CUSTOM_FIELDS'
                      ]?.value?.map((item: any) => ({
                        label: item?.text,
                        value: item?.text,
                      }))}
                      className={`focus:outline-none rounded-lg w-full mt-2`}
                      placeholder={'Select '}
                      isMulti={false}
                      label=""
                      onChange={(option: any) =>
                        handleChange('dropDown1', option?.value, condition?.id)
                      }
                    />
                  </div>
                  <div className="w-full">
                    <Select
                      // @ts-ignore
                      options={allOperators}
                      className={`focus:outline-none rounded-lg w-full mt-2`}
                      placeholder={'Select operator'}
                      isMulti={false}
                      label=""
                      value={
                        []?.filter(
                          (operator: any) =>
                            operator?.value === condition?.dropDown2,
                        )?.[0]
                      }
                      onChange={(option: any) =>
                        handleChange('dropDown2', option?.value, condition?.id)
                      }
                    />
                  </div>

                  <div>
                    {!formulaBuilderVisible ? (
                      <Input
                        // @ts-ignore
                        className="!p-[7px]"
                        value={condition?.formula?.displayFormula}
                        onClick={() => setFormulaBuilderVisible(true)}
                      />
                    ) : (
                      <FormulaBuilder
                        setModel={setFormulaBuilderVisible}
                        saveAction={(formula: any) =>
                          handleChange('formulaDetails', formula, condition?.id)
                        }
                        moduleName={'Compensation'}
                        sourceId={undefined}
                        selectedColumn={undefined}
                      />
                    )}
                  </div>
                </div>
                <div
                  className=" flex justify-end w-[5%]"
                  onClick={() =>
                    conditions?.length > 1 &&
                    setConditions(
                      conditions?.filter(
                        (item: any) => item?.id !== condition?.id,
                      ),
                    )
                  }
                >
                  <TrashIcon className="w-5 h-5 cursor-pointer" />
                </div>
              </div>
            );
          })}
          <div
            className="flex items-center  w-fit gap-2 py-4 cursor-pointer text-sm font-semibold"
            onClick={() =>
              setConditions([...conditions, { id: v4(), formulaDetails }])
            }
          >
            <PlusIcon className="w-4 h-5" />
            Add Condition
          </div>
        </div>
      </div>
    </div>
  );
};
