import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import EditableTable from 'components/molecules/editableTable/EditableTable';
import {
  useGetAllEmployeeFields,
  useGetAllInputFieldsValue,
} from 'query/benefits/benefits';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEligibilityRowOptions } from 'store/reducers/compensationSlice';
import { v4 } from 'uuid';

const sidePanelColumns = [
  'indivAcheiveWeightage',
  'departmentWeightage',
  'total',
];

const initialColumns = [
  { fieldId: v4() },
  { fieldId: v4() },
  { fieldId: v4() },
  { fieldId: v4() },
];

export const MetricsWeightage = () => {
  const [isSectionOpen, setIsSectionOpen] = useState(true);
  const [rows, setRows] = useState<any>([{}]);
  const [columns, setColumns] = useState(initialColumns);
  const [options, setOptions] = useState([]);
  const { data, isLoading } = useGetAllEmployeeFields();
  const { mutateAsync } = useGetAllInputFieldsValue();
  const { subOptions } = useSelector(
    (state: any) => state?.compensation?.eligibilityLimites,
  );
  const dispatch = useDispatch();

  const onAddRow = () => {
    const newRow: any = {};
    columns.forEach(({ fieldId }) => {
      newRow[fieldId] = '';
    });
    setRows([...rows, newRow]);
  };

  const onRemoveRow = (index: number) => {
    const updatedRows = rows.filter((_: any, i: number) => i !== index);
    setRows(updatedRows);
  };

  useEffect(() => {
    if (data) {
      const option = data?.map(
        ({ id, alias, name }: { id: string; alias: string; name: string }) => {
          return {
            id,
            label: alias,
            value: name,
          };
        },
      );
      setOptions(option);
    }
  }, [data]);

  return (
    <div className="p-6 space-y-5">
      <div className="p-5 rounded-xl space-y-3 bg-gray-50">
        <p className="font-semibold text-gray-900">Notes</p>
        <div className="space-y-[10px]">
          <div>
            <p className="text-sm text-gray-600">{`In case the "Addition" option is selected below, the total payout will be calculated by summing the values of each metric using the following formula:`}</p>
            <p className="text-sm font-medium text-gray-900">{`Total Payout = (Metric 1 x Weightage x Achievement x Proration) + (Metric 2 x Weightage x Achievement x Proration) + ...`}</p>
          </div>
          <div>
            <p className="text-sm text-gray-600">{`And if “Multiplication” option is selected below, the total payout will be calculated by multipplying the values of each metric using the following formula:`}</p>
            <p className="text-sm font-medium text-gray-900">{`Total Payout = (Metric 1 x Weightage x Achievement x Proration) x (Metric 2 x Weightage x Achievement x Proration) x ...`}</p>
          </div>
        </div>
      </div>
      <EditableTable
        title="Metrics Weightage"
        isSectionOpen={isSectionOpen}
        setIsSectionOpen={setIsSectionOpen}
        onAddRow={onAddRow}
        handleDownloadClick={undefined}
        handleUploadClick={undefined}
        RightComponent={undefined}
        budgetLabel={undefined}
        isDownloading={undefined}
        subType={undefined}
        type={undefined}
      >
        <EditableTable.Header
          columns={columns}
          onRemoveColumn={undefined}
          isTableComponent={false}
          render={(index: number, id: string) => (
            <Select
              // @ts-ignore
              options={options}
              className="w-[14rem] border-0 bg-gray-50"
              isClearable={true}
              onChange={(e: any) => {
                if (e?.id) {
                  mutateAsync(e?.id).then((res) => {
                    const options = res?.data?.map((ele: any) => ({
                      id: ele.id,
                      label: ele.value,
                      value: ele.value,
                    }));
                    if (!subOptions[id]) {
                      dispatch(
                        setEligibilityRowOptions({
                          fieldId: id,
                          options,
                        }),
                      );
                    }
                  });
                }
              }}
            />
          )}
        />
        <EditableTable.Row
          rows={rows}
          columns={columns}
          sidePanelColumns={sidePanelColumns}
          onRemoveRow={onRemoveRow}
          isTableComponent={false}
          render={(index: number, { fieldId }: { fieldId: string }) => (
            <Select
              // @ts-ignore
              options={subOptions?.[fieldId] || []}
              isClearable={true}
              className="w-[14rem] border-0"
            />
          )}
        />
        <EditableTable.SidePanel
          columns={['Indiv Achieve Weightage', 'Department Weightage', 'Total']}
          rows={rows}
          sidePanelColumns={sidePanelColumns}
          onRemoveRow={onRemoveRow}
          render={(index: number, id: string) => (
            <Input
              // @ts-ignore
              placeholder="Enter value"
            />
          )}
          width="100%"
          defaultValue=""
          onAddColumn={undefined}
          disableCloseBtn={false}
          isUploadDownload={false}
          customHeadingComponent={undefined}
        />
      </EditableTable>
    </div>
  );
};
