import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import ButtonLoader from 'assets/svg/buttonLoader';
import ToolTipComponent from 'components/atoms/tooltip/ToolTipComponent';
import ConfirmModalPopup from 'pages/rangeBuilder/ConfirmModalPopUp';
import React, { useState } from 'react';

const getChildrenOnDisplayName = (children, displayName) => {
  const matchingChildren = [];
  React.Children.forEach(children, (child) => {
    if (child.type.displayName === displayName) {
      matchingChildren.push(child);
    }
  });
  return matchingChildren;
};

function EditableTable({
  title,
  children,
  isSectionOpen,
  setIsSectionOpen,
  onAddRow,
  isUploadDownload = false,
  handleDownloadClick,
  handleUploadClick,
  RightComponent,
  budgetLabel,
  isDownloading,
  subType,
  type,
}) {
  const sidePanel = getChildrenOnDisplayName(children, 'SidePanel');
  const header = getChildrenOnDisplayName(children, 'Header');
  const row = getChildrenOnDisplayName(children, 'Row');

  return (
    <div className="border rounded-xl p-4 shadow-sm relative bg-white">
      <div className="flex justify-between items-center min-w-full">
        <h1 className="text-gray-900 text-md font-semibold">
          {budgetLabel?.sublabels?.[type]?.[
            subType === 'correction' ? 'marketCorrection' : subType
          ] || title}
        </h1>
        <div className="flex gap-2 items-center">
          {RightComponent}
          {isUploadDownload && (
            <div className="flex gap-2 ">
              <ToolTipComponent
                id="downloadBudgetSummary"
                text={'Download Budget Summary'}
              />
              <div
                data-tooltip-id="downloadBudgetSummary"
                className="p-2 border rounded-lg hover:cursor-pointer "
                onClick={handleDownloadClick}
              >
                {isDownloading ? (
                  <ButtonLoader />
                ) : (
                  <ArrowDownIcon className="w-5 h-5" />
                )}
              </div>
              <ToolTipComponent
                id="uploadBudgetSummary"
                text={'Upload Budget Summary'}
              />
              <div
                data-tooltip-id="uploadBudgetSummary"
                className="p-2 border rounded-lg hover:cursor-pointer"
                onClick={handleUploadClick}
              >
                <ArrowUpIcon className="w-5 h-5" />
              </div>
            </div>
          )}

          <span onClick={() => setIsSectionOpen(!isSectionOpen)}>
            <ChevronRightIcon
              className={`w-4 h-4 transition-all ${
                isSectionOpen && 'rotate-90'
              }`}
            />
          </span>
        </div>
      </div>
      {isSectionOpen && (
        <React.Fragment>
          <div className=" max-w-full overflow-x-auto">
            {header}
            {row}
          </div>
          {sidePanel}
          <div
            className="mt-5 p-5 border bg-gray-50 rounded-lg text-gray-900 font-medium text-sm cursor-pointer"
            onClick={() => onAddRow()}
          >
            + Add Row
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

// onRemoveColumn(column.id)

function Header({ columns, render, onRemoveColumn, isTableComponent }) {
  const [isConfirmationPopupVisible, setIsConfirmationPopupVisible] =
    useState(false);

  return (
    !!columns.length && (
      <div className="border bg-gray-50 rounded-lg p-3 mt-5 min-w-fit pr-10">
        <div className="flex gap-3">
          {columns.map((column, index) => {
            return (
              <div
                key={index + 500}
                className="min-w-[15.625rem] grid grid-cols-[1fr_1.25rem] items-center gap-3"
              >
                {isConfirmationPopupVisible === index && (
                  <ConfirmModalPopup
                    setModalVisible={setIsConfirmationPopupVisible}
                    confirmType={'DELETE'}
                    onClick={() => {
                      onRemoveColumn(index);
                      setIsConfirmationPopupVisible(null);
                    }}
                    loading={
                      false
                      //   removing?.id === field?.id &&
                      //   removing?.loading
                    }
                    type="Column"
                  />
                )}
                {isTableComponent ? (
                  <>
                    {render(index, column.id, column?.name)}{' '}
                    <span
                      onClick={() => {
                        onRemoveColumn(column.id);
                      }}
                    >
                      <XMarkIcon className="cursor-pointer" />
                    </span>
                  </>
                ) : (
                  <>
                    {render(index, column.fieldId)}
                    {index !== columns?.length - 1 ? (
                      <span>
                        <ChevronRightIcon className="h-5 w-5 cursor-pointer" />
                      </span>
                    ) : onRemoveColumn ? (
                      <span
                        onClick={() => setIsConfirmationPopupVisible(index)}
                      >
                        <XMarkIcon className="cursor-pointer" />
                      </span>
                    ) : null}
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    )
  );
}

Header.displayName = 'Header';
EditableTable.Header = Header;

function Row({
  rows,
  sidePanelColumns,
  onRemoveRow,
  render,
  columns,
  isTableComponent,
}) {
  const [isConfirmationPopupVisible, setIsConfirmationPopupVisible] =
    useState(false);

  return (
    <div className="mt-2">
      {rows.map((row, index) => (
        <div
          className="border rounded-lg p-3 flex items-center justify-between mt-2 min-w-fit pr-10"
          key={index}
        >
          {isTableComponent ? (
            <>
              <div className="flex gap-11">
                {columns
                  ? columns?.map((column, colIndex) => (
                      <div
                        className="grid grid-cols-[1fr] items-center"
                        key={column?.id}
                      >
                        {render(index, column, row, colIndex)}
                      </div>
                    ))
                  : rows &&
                    Object.keys(row)
                      ?.filter((field) => field !== 'id')
                      .map((field) => {
                        if (sidePanelColumns.includes(field)) {
                          return null;
                        } else {
                          return (
                            <div
                              className="grid grid-cols-[1fr] items-center"
                              key={index + 1500}
                            >
                              {render(index, field)}
                            </div>
                          );
                        }
                      })}
              </div>
            </>
          ) : (
            <div className="flex gap-11">
              {columns
                ? columns?.map((column, colIndex) => (
                    <div
                      className="grid grid-cols-[1fr] items-center"
                      key={index + column?.fieldId}
                    >
                      {render(index, column)}
                    </div>
                  ))
                : rows &&
                  Object.keys(row)
                    ?.filter((field) => field !== 'id')
                    .map((field) => {
                      if (sidePanelColumns.includes(field)) {
                        return null;
                      } else {
                        return (
                          <div
                            className="grid grid-cols-[1fr] items-center"
                            key={index + 2333}
                          >
                            {render(index, field)}
                          </div>
                        );
                      }
                    })}
            </div>
          )}
          {isConfirmationPopupVisible === index && (
            <ConfirmModalPopup
              setModalVisible={setIsConfirmationPopupVisible}
              confirmType={'DELETE'}
              onClick={() => {
                onRemoveRow(index);
                setIsConfirmationPopupVisible(null);
              }}
              loading={
                false
                //   removing?.id === field?.id &&
                //   removing?.loading
              }
              type="Row"
            />
          )}
          {!isTableComponent && (
            <div className=" ml-4">
              <XMarkIcon
                className="h-5 w-5 cursor-pointer"
                onClick={() => setIsConfirmationPopupVisible(index)}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
Row.displayName = 'Row';
EditableTable.Row = Row;

function SidePanel({
  columns,
  onAddColumn,
  rows,
  render,
  sidePanelColumns,
  onRemoveRow,
  width,
  defaultValue,
  disableCloseBtn,
  isUploadDownload,
  isCustomHeading = false,
  customHeadingComponent,
}) {
  const [isSidePanel, setSidePanel] = useState(defaultValue || false);
  const [isConfirmationPopupVisible, setIsConfirmationPopupVisible] =
    useState(false);

  return (
    <div
      className={`absolute ${
        isUploadDownload ? 'top-[4.6rem]' : 'top-[3.8rem]'
      } top-5  right-0`}
      style={{
        pointerEvents: 'auto',
      }}
    >
      <div
        className={` relative min-h-full transition-all shadow ${
          isSidePanel
            ? `${width?.includes('px') ? `w-[${width}]` : `w-${width}`}`
            : 'w-0'
        }`}
      >
        <div
          className={`absolute rounded-lg rounded-tr-none rounded-br-none left-[-2.8125rem] ${
            onAddColumn ? 'bottom-4' : 'top-4'
          } border shadow-sm border-r-0 h-fit w-fit bg-gray-50 text-gray-900 py-2 font-semibold px-3 text-xl cursor-pointer`}
          onClick={() => setSidePanel(!isSidePanel)}
        >
          <ChevronLeftIcon
            className={`cursor-pointer w-5 h-5 transition-all ${
              isSidePanel && 'rotate-180'
            }`}
          />
        </div>
        {onAddColumn && (
          <div
            className="absolute rounded-lg rounded-tr-none rounded-br-none left-[-2.8125rem] top-4 border shadow-sm border-r-0 h-fit w-fit bg-gray-50 text-gray-900 py-2 font-semibold px-3 text-xl cursor-pointer"
            onClick={(e) => onAddColumn(e)}
          >
            <PlusIcon className="h-5 w-5" />
          </div>
        )}
        {
          <div className={` h-full bg-white ${!isSidePanel && 'opacity-0'}`}>
            <div className="grid grid-cols-[1fr_1.875rem] bg-gray-50 border">
              <div
                className={`p-3 px-6 grid grid-cols-${sidePanelColumns.length} items-center gap-5 h-[3.9813rem]`}
              >
                {/* {columns?.map((name, index) => {
                  return (
                    <div
                      key={name}
                      className="font-semibold text-gray-600 text-sm"
                    >
                      {name}
                    </div>
                  );
                })} */}
                {isCustomHeading
                  ? columns?.map((name, index) => {
                      return (
                        <div key={name?.id}>{customHeadingComponent(name)}</div>
                      );
                    })
                  : columns?.map((name, index) => {
                      return (
                        <div
                          key={name}
                          className="font-semibold text-gray-600 text-sm"
                        >
                          {name}
                        </div>
                      );
                    })}
              </div>
              <div></div>
            </div>

            {rows?.map((row, index) => {
              return (
                <div
                  className="grid grid-cols-[1fr_1.875rem] items-center "
                  key={index + 4000}
                >
                  <div
                    className={` bg-white p-3 px-6 grid grid-cols-${sidePanelColumns.length} items-center gap-5 h-[3.9813rem] mt-2`}
                  >
                    {sidePanelColumns?.map((field) => render(index, field))}
                  </div>
                  {isConfirmationPopupVisible === index && (
                    <ConfirmModalPopup
                      setModalVisible={setIsConfirmationPopupVisible}
                      confirmType={'DELETE'}
                      onClick={() => {
                        onRemoveRow(index);
                        setIsConfirmationPopupVisible(null);
                      }}
                      loading={
                        false
                        //   removing?.id === field?.id &&
                        //   removing?.loading
                      }
                      type="Row"
                    />
                  )}
                  {!disableCloseBtn && (
                    <span className="inline w-5">
                      <XMarkIcon
                        className="h-5 w-5 cursor-pointer inline-block"
                        onClick={() => setIsConfirmationPopupVisible(index)}
                      />
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        }
      </div>
    </div>
  );
}

SidePanel.displayName = 'SidePanel';
EditableTable.SidePanel = SidePanel;

export default EditableTable;
