import {
  getAllInputFields,
  getInputFieldCriteria,
} from 'apiClient/compensation/compensation';
import Button from 'components/atoms/button/Button';
import QueryMaker from 'components/molecules/QueryBuilder/QueryMaker';
import {
  queryBuilderFormatterForAPI,
  queryBuilderFormatterForUI,
} from 'components/molecules/QueryBuilder/queryMakerUtils';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import BulkUserUploadForEligibility from './BulkUserUploadForEligibility';
import EligibleUsers from './EligibleUsers';
import { getMasterColumnsList } from 'apiClient/rbac/rbac';
export default function FilterForEligibleUsers({
  saveApiCall,
  fetchEligibleUser,
  formatIndex,
  savedData,
  selectedPrimaryTab,
  userList,
  valueStroreInParent,
  isLoading,
  hideExclude = false,
  usedForMaster = false,
  showSaveButton = true,
  hideBulkUpload = false,
  title,
  dropdownList = [],
  isSaving,
  planId,
  viewMode,
  downloadEligibleGroupUsers,
}) {
  const [savedIncludeCondition, setSavedIncludeCondition] = useState({
    combinator: 'and',
    rules: [],
  });
  const [updatedIncludeCondition, setUpdatedIncludeCondition] = useState({
    combinator: 'and',
    rules: [],
  });
  const [savedExcludeCondition, setSavedExcludeCondition] = useState({
    combinator: 'and',
    rules: [],
  });
  const [updatedExcludeCondition, setUpdatedExcludeCondition] = useState({
    combinator: 'and',
    rules: [],
  });
  const [fields, setFields] = useState([]);
  const [operators, setOperators] = useState({});
  const [hideSummary, setShowSummary] = useState(true);
  const [isEdited, setIsEdited] = useState(false);
  useEffect(() => {
    if (
      savedData?.criteriaInclusion &&
      savedData?.criteriaExclusion &&
      fields?.length > 0 &&
      Object.keys(operators)?.length > 0
    ) {
      setSavedIncludeCondition(
        queryBuilderFormatterForUI(
          formatIndex,
          savedData?.criteriaInclusion,
          fields,
          operators,
        ),
      );
      setUpdatedIncludeCondition(
        queryBuilderFormatterForUI(
          formatIndex,
          savedData?.criteriaInclusion,
          fields,
          operators,
        ),
      );
      setSavedExcludeCondition(
        queryBuilderFormatterForUI(
          formatIndex,
          savedData?.criteriaExclusion,
          fields,
          operators,
        ),
      );
      setUpdatedExcludeCondition(
        queryBuilderFormatterForUI(
          formatIndex,
          savedData?.criteriaExclusion,
          fields,
          operators,
        ),
      );
    }
  }, [
    savedData?.criteriaInclusion,
    savedData?.criteriaExclusion,
    fields,
    operators,
  ]);

  //   const onClickCancel = () => {
  //     setIncludeCondition({
  //       combinator: 'and',
  //       rules: [],
  //     });
  //     setExcludeCondition({
  //       combinator: 'and',
  //       rules: [],
  //     });
  //   };
  const fetchFields = () => {
    getAllInputFields()
      .then((res) => {
        setFields([
          ...res,
          {
            id: 'user',
            groupId: null,
            name: 'user',
            alias: 'User',
            regex: '*',
            helpText: 'User',
            isActive: true,
            order: 15,
            isMandatory: true,
            entityName: null,
            masterSourceColumnId: null,
            showOnFilterDropdown: true,
            dataType: 'User',
          },
        ]);
      })
      .catch((err) => console.log(err));
  };

  const fetchOperators = () => {
    getInputFieldCriteria('datatype', 'All?map=1')
      .then((res) => {
        setOperators({
          ...res,
          User: [
            {
              code: 'id',
              displayName: 'Id',
            },
          ],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addBulkUsers = (userData, remove) => {
    if (userData?.type === 'include') {
      if (remove) {
        setUpdatedIncludeCondition({ id: v4(), combinator: 'and', rules: [] });
        setSavedIncludeCondition({ id: v4(), combinator: 'and', rules: [] });
      } else {
        const data = {
          id: v4(),
          combinator: 'and',
          rules: [
            {
              id: v4(),
              field: { label: 'Employees', id: 'user', value: 'user' },
              operator: {
                code: 'id-in',
                displayName: 'Id-in',
                id: 'id-in',
                value: 'id-in',
                label: 'Id-in',
              },
              value: {
                id: v4(),
                value: userData?.['includeUserEligibleGroup']?.['id-in']?.[1],
                isActive: true,
                label: userData?.['includeUserEligibleGroup']?.['id-in']?.[1],
              },
            },
          ],
        };
        setUpdatedIncludeCondition(data);
      }
    } else {
      if (remove) {
        setUpdatedExcludeCondition({ id: v4(), combinator: 'and', rules: [] });
        setSavedExcludeCondition({ id: v4(), combinator: 'and', rules: [] });
      } else {
        const data = {
          id: v4(),
          combinator: 'and',
          rules: [
            {
              id: v4(),
              field: { label: 'Employees', id: 'user', value: 'user' },
              operator: {
                code: 'id-in',
                displayName: 'Id-in',
                id: 'id-in',
                value: 'id-in',
                label: 'Id-in',
              },
              value: {
                id: '37e0d926-c68d-4c74-afd7-79a9e7c4fc93',
                value: userData?.['excludeUserEligibleGroup']?.['id-in']?.[1],
                isActive: true,
                label: userData?.['excludeUserEligibleGroup']?.['id-in']?.[1],
              },
            },
          ],
        };
        setUpdatedExcludeCondition(data);
      }
    }
  };

  useEffect(() => {
    if (!usedForMaster) {
      fetchFields();
    }
    fetchOperators();
  }, []);
  useEffect(() => {
    if (dropdownList?.length > 0) {
      setFields(dropdownList);
    }
  }, [dropdownList]);

  const areObjectsEqual = (obj1, obj2) => isEqual(obj1, obj2);
  const newSavedData = useMemo(() => {
    if (
      valueStroreInParent &&
      (updatedIncludeCondition || updatedExcludeCondition)
    ) {
      return {
        criteriaInclusion: queryBuilderFormatterForAPI(updatedIncludeCondition),
        criteriaExclusion: queryBuilderFormatterForAPI(updatedExcludeCondition),
      };
    }
    return null; // or any default state if valueStroreInParent is not true
  }, [valueStroreInParent, updatedIncludeCondition, updatedExcludeCondition]);
  useEffect(() => {
    if (!newSavedData || !savedData) return;
    const hasChanges =
      !areObjectsEqual(
        newSavedData?.criteriaInclusion,
        savedData?.criteriaInclusion,
      ) ||
      !areObjectsEqual(
        newSavedData?.criteriaExclusion,
        savedData?.criteriaExclusion,
      );

    if (hasChanges) {
      saveApiCall(newSavedData);
    } else if (valueStroreInParent) {
      saveApiCall(savedData);
    }
  }, [newSavedData, savedData, valueStroreInParent]);

  useEffect(() => {
    if (
      areObjectsEqual(savedIncludeCondition, updatedIncludeCondition) &&
      areObjectsEqual(savedExcludeCondition, updatedExcludeCondition)
    ) {
      setIsEdited(false);
    } else {
      setIsEdited(true);
    }
  }, [
    savedExcludeCondition,
    updatedExcludeCondition,
    savedIncludeCondition,
    updatedIncludeCondition,
  ]);
  if (isLoading) {
    return 'Loading..';
  }

  if (formatIndex && savedData)
    return (
      <div className="flex gap-4 w-full">
        {' '}
        <div
          className={`${
            hideSummary ? 'w-full' : ' w-[calc(100vw-360px)]'
          } border rounded-lg p-4 border-gray-200 bg-gray-100  `}
        >
          <div>
            <div className="flex justify-between items-center mb-4">
              <h3 className="font-semibold text-xl ">
                {title ? title : 'Filters'}
              </h3>

              {showSaveButton && (
                <div className="flex items-center gap-4">
                  {' '}
                  <p className="text-md text-red-500">{''}</p>
                  {/* <Button onClick={() => onClickCancel()} variant="outline">
                    Cancel
                  </Button> */}
                  <Button
                    disabled={isSaving || !isEdited}
                    onClick={() => {
                      const body = {
                        criteriaInclusion: queryBuilderFormatterForAPI(
                          updatedIncludeCondition,
                        ),
                        criteriaExclusion: queryBuilderFormatterForAPI(
                          updatedExcludeCondition,
                        ),
                      };
                      saveApiCall(body);
                    }}
                    variant="filled"
                  >
                    {isSaving ? 'Saving ...' : 'Save'}
                  </Button>
                </div>
              )}
            </div>

            <div
              className="bg-white p-4 rounded-lg border z-10 mt-8"
              style={{
                pointerEvents: viewMode ? 'none' : 'auto',
              }}
            >
              <p className="mb-2 font-semibold text-lg">Include</p>

              {updatedIncludeCondition?.rules?.[0]?.operator?.value !==
                'id-in' && (
                <div className=" border rounded-lg  border-gray-200 bg-gray-50">
                  <QueryMaker
                    updateInitialQuery={setUpdatedIncludeCondition}
                    initialQuery={savedIncludeCondition || null}
                    dataIndex={formatIndex}
                    operators={operators}
                    fields={fields}
                  />
                </div>
              )}
              <div>
                {!hideBulkUpload &&
                  (updatedIncludeCondition?.rules?.length === 0 ||
                    updatedIncludeCondition?.rules?.[0]?.operator?.value ===
                      'id-in') && (
                    <div>
                      {(updatedIncludeCondition?.rules?.length === 0 ||
                        updatedIncludeCondition?.rules?.[0]?.value?.length ===
                          0) && (
                        <div className="flex flex-col justify-center items-center my-2">
                          <div className="h-4 w-0.5 bg-gray-200"></div>
                          <div className="text-gray-500 text-lg font-normal">
                            {' '}
                            <span className="font-semibold ">
                              + Add Rule
                            </span>{' '}
                            or bulk upload Employees
                          </div>
                          <div className="h-4 w-0.5 bg-gray-200"></div>
                        </div>
                      )}
                      <div className=" border rounded-lg  border-gray-200 bg-gray-50">
                        <BulkUserUploadForEligibility
                          type="include"
                          planType={selectedPrimaryTab}
                          planId={planId}
                          addBulkUsers={addBulkUsers}
                          value={
                            updatedIncludeCondition?.rules?.[0]?.value?.value ||
                            savedIncludeCondition?.rules?.[0]?.value ||
                            null
                          }
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
            {!hideExclude && (
              <div
                className="bg-white p-4 rounded-lg border z-10 mt-8"
                style={{
                  pointerEvents: viewMode ? 'none' : 'auto',
                }}
              >
                <p className="mb-2 font-semibold text-lg">Exclude</p>
                {updatedExcludeCondition?.rules?.[0]?.operator?.value !==
                  'id-in' && (
                  <div className=" border rounded-lg  border-gray-200 bg-gray-50">
                    <QueryMaker
                      updateInitialQuery={setUpdatedExcludeCondition}
                      initialQuery={savedExcludeCondition || null}
                      savedQuery={savedData?.criteriaExclusion}
                      dataIndex={formatIndex}
                      operators={operators}
                      fields={fields}
                    />
                  </div>
                )}
                <div>
                  {!hideBulkUpload &&
                    (updatedExcludeCondition?.rules?.length === 0 ||
                      updatedExcludeCondition?.rules?.[0]?.operator?.value ===
                        'id-in') && (
                      <div>
                        {(updatedExcludeCondition?.rules?.length === 0 ||
                          updatedExcludeCondition?.rules?.[0]?.value?.length ===
                            0) && (
                          <div className="flex flex-col justify-center items-center my-2">
                            <div className="h-4 w-0.5 bg-gray-200"></div>
                            <div className="text-gray-500 text-lg font-normal">
                              {' '}
                              <span className="font-semibold ">
                                + Add Rule
                              </span>{' '}
                              or bulk upload Employees
                            </div>
                            <div className="h-4 w-0.5 bg-gray-200"></div>
                          </div>
                        )}
                        <div className=" border rounded-lg  border-gray-200 bg-gray-50">
                          <BulkUserUploadForEligibility
                            type="exclude"
                            planType={selectedPrimaryTab}
                            planId={planId}
                            addBulkUsers={addBulkUsers}
                            value={
                              updatedExcludeCondition?.rules?.[0]?.value
                                ?.value ||
                              savedExcludeCondition?.rules?.[0]?.value ||
                              null
                            }
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
        {!usedForMaster && (
          <div>
            <EligibleUsers
              hideSummary={hideSummary}
              fetchEligibleUser={fetchEligibleUser}
              setShowSummary={setShowSummary}
              savedData={savedData}
              downloadEligibleGroupUsers={downloadEligibleGroupUsers}
            />
          </div>
        )}
      </div>
    );
}
