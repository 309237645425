import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import Table from 'components/atoms/table/Table';
import TableHeader from 'components/atoms/tableHeader/TableHeader';
import { payoutFrequencyDropdown } from 'config/variablePay';
import { useEffect, useState } from 'react';
import './PayoutCriteria.scss';

export const PayoutCriteria = () => {
  const [data, setData] = useState([{}]);
  const [selectedPayoutFrequency, setSelectedPayoutFrequency] = useState(
    payoutFrequencyDropdown?.[0]?.value,
  );

  const columns = [
    {
      id: '1',
      width: '16%',
      Header: (
        <TableHeader name="For Period" HeaderKey="forPeriod" sort={true} />
      ),
      Cell: ({ row }: any) => {
        return (
          <Input
            // @ts-ignore
            value="Apr, Year 0 - Mar, Year 1"
          />
        );
      },
    },
    {
      id: '2',
      width: '13%',
      Header: (
        <TableHeader name="Payout Date" HeaderKey="payoutDate" sort={true} />
      ),
      Cell: ({ row }: any) => {
        return (
          <div className="flex items-center gap-3">
            <div className="w-[51%]">
              <Select
                // @ts-ignore
                placeholder="MM"
              />
            </div>
            <div className="w-[47%]">
              <Select
                // @ts-ignore
                placeholder="YY"
              />
            </div>
          </div>
        );
      },
    },
    {
      id: '3',
      width: '19%',
      Header: (
        <TableHeader
          name="Active Cut-off Date"
          HeaderKey="activeCutOffDate"
          sort={true}
        />
      ),
      Cell: ({ row }: any) => {
        return (
          <div className="flex items-center gap-3">
            <div className="w-[31.8%]">
              <Select
                // @ts-ignore
                placeholder="DD"
              />
            </div>
            <div className="w-[34%]">
              <Select
                // @ts-ignore
                placeholder="MM"
              />
            </div>
            <div className="w-[31.3%]">
              <Select
                // @ts-ignore
                placeholder="YY"
              />
            </div>
          </div>
        );
      },
    },
    {
      id: '4',
      width: '10%',
      Header: (
        <TableHeader
          name="Target Proration"
          HeaderKey="targetProration"
          sort={true}
        />
      ),
      Cell: ({ row }: any) => {
        return <Select />;
      },
    },
    {
      id: '5',
      width: '10%',
      Header: (
        <TableHeader
          name="Payout Proration"
          HeaderKey="payoutProration"
          sort={true}
        />
      ),
      Cell: ({ row }: any) => {
        return <Select />;
      },
    },
    {
      id: '6',
      width: '13%',
      Header: (
        <TableHeader
          name="Clawback Date"
          HeaderKey="clawbackDate"
          sort={true}
        />
      ),
      Cell: ({ row }: any) => {
        return (
          <div className="flex items-center gap-3">
            <div className="w-[51%]">
              <Select
                // @ts-ignore
                placeholder="MM"
              />
            </div>
            <div className="w-[47%]">
              <Select
                // @ts-ignore
                placeholder="YY"
              />
            </div>
          </div>
        );
      },
    },
    {
      id: '7',
      width: '10%',
      Header: (
        <TableHeader
          name="Clawback Proration"
          HeaderKey="clawbackProration"
          sort={true}
        />
      ),
      Cell: ({ row }: any) => {
        return (
          <div className="flex items-center gap-3">
            <Select />
          </div>
        );
      },
    },
    {
      id: '8',
      width: '9%',
      Header: (
        <TableHeader
          name="Clawback Calculation"
          HeaderKey="clawbackCalculation"
          sort={true}
        />
      ),
      Cell: ({ row }: any) => {
        return (
          <Input
            // @ts-ignore
            placeholder="Enter value"
          />
        );
      },
    },
  ];

  useEffect(() => {
    setData(
      Array.from(
        {
          length:
            selectedPayoutFrequency === 'Annually'
              ? 1
              : selectedPayoutFrequency === 'Bi-annually'
              ? 2
              : selectedPayoutFrequency === 'Quarterly'
              ? 4
              : 12,
        },
        () => ({}),
      ),
    );
  }, [selectedPayoutFrequency]);

  return (
    <div className="border border-gray-200 rounded-xl bg-white p-6 shadow-md space-y-4">
      <div className="flex items-center justify-between">
        <p className="font-semibold text-gray-900">Payout Criteria</p>
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[49%]">
          <Select
            //@ts-ignore
            label="Payout Frequency"
            options={payoutFrequencyDropdown}
            onChange={({ value }: { value: string }) =>
              setSelectedPayoutFrequency(value)
            }
            value={payoutFrequencyDropdown?.filter(
              ({ value }: { value: string }) =>
                value === selectedPayoutFrequency,
            )}
          />
        </div>
        <div className="w-[49%]">
          <Select
            //@ts-ignore
            label="Payout Cycle"
          />
        </div>
      </div>
      <div className="noBorderTable">
        <Table columns={columns} data={data} />
      </div>
    </div>
  );
};
