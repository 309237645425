import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import {
  createEligibility,
  getPlanById,
  getPlanDetails,
  saveEvents,
} from 'apiClient/variablePay/variablePay';
import Button from 'components/atoms/button/Button';
import { planSetupTabs } from 'config/variablePay';
import { formatDate } from 'pages/recognition/orderHistory/helper';
import { setToastMessage } from 'pages/recognition/redemption/helper';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { PlanSetUp } from './planSetup/PlanSetUp';
import {
  ConfigureClawbackEnum,
  EnableProrationEnum,
  EventsSettings,
  PayoutAmountsEnum,
} from './VariablePay.types';

const primaryTabs = ['Plan Setup', 'Letter Generation'];

export const VariablePayPlan = () => {
  const [selectedPrimaryTab, setSelectedPrimaryTab] = useState<string>(
    primaryTabs[0],
  );
  const [selectedTab, setSelectedTab] = useState<string>(
    planSetupTabs[0].tabLabel,
  );
  const [eventSettings, setEventSettings] = useState<EventsSettings>({
    vPayPayoutAmounts: { value: PayoutAmountsEnum.PRECALCULATED },
    vPayEnableProration: {
      value: [
        EnableProrationEnum.BASED_ON_EMPLOYEE_DATA,
        EnableProrationEnum.PRORATION_OCCURENCE,
      ],
    },
    vPayEnableClawback: {
      value: [
        ConfigureClawbackEnum.CONFIGURE_CLAWBACK,
        ConfigureClawbackEnum.CONFIGURE_PRORATION_CLAWBACK,
      ],
    },
  });
  const [tableData, setTableData] = useState<any>([[{ id: v4() }]]);
  const navigate = useNavigate();
  const [eligibleUserData, setEligibleUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const { id } = useParams();
  const [planDetails, setPlanDetails] = useState<any>({});

  useEffect(() => {
    (async () => {
      try {
        const response = await getPlanDetails(id);
        setPlanDetails(response?.data);
      } catch (error) {
        setPlanDetails({});
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await getPlanById(id);

        const eventData = response?.data?.config;
        delete eventData?.userGroups;
        delete eventData?.id;
        delete eventData?.planId;
        setEventSettings({ ...eventData } as EventsSettings);
      } catch (error) {
        setEventSettings({
          vPayPayoutAmounts: { value: PayoutAmountsEnum.PRECALCULATED },
          vPayEnableProration: {
            value: [
              EnableProrationEnum.BASED_ON_EMPLOYEE_DATA,
              EnableProrationEnum.PRORATION_OCCURENCE,
            ],
          },
          vPayEnableClawback: {
            value: [
              ConfigureClawbackEnum.CONFIGURE_CLAWBACK,
              ConfigureClawbackEnum.CONFIGURE_PRORATION_CLAWBACK,
            ],
          },
        });
      }
    })();
  }, []);

  const handleSave = async () => {
    try {
      setLoading(true);
      if (selectedTab === 'Settings') {
        await saveEvents({
          configJson: {
            ...eventSettings,
            userGroups: { ...tableData },
          },
          planId: id,
        });
        setToastMessage('Settings saved successfully!');
      } else if (selectedTab === 'Eligible Group') {
        await createEligibility(eligibleUserData);
        setToastMessage('Eligibility saved successfully!');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setToastMessage('Something went wrong!');
    }
  };

  return (
    <div className="bg-white h-full">
      <div className="py-6 px-8">
        <div className="flex items-center justify-between">
          <div className="flex items-start gap-4">
            <div
              className="p-2 rounded-lg border-2 cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <ArrowSmallLeftIcon className="w-5 h-5" />
            </div>
            <div className="flex flex-col text-xl font-semibold">
              <p className="text-lg font-semibold text-gray-900">
                {planDetails?.planName}
              </p>
              <p className="text-sm font-normal text-gray-600">
                {`ID ${planDetails?.planId} | Plan Start Date: ${formatDate(
                  new Date(planDetails?.startDate),
                )} | Plan End Date: ${formatDate(
                  new Date(planDetails?.endDate),
                )}`}
              </p>
            </div>
          </div>
          <div>
            <Button variant="filled" onClick={handleSave}>
              {loading ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </div>
      </div>
      <div className="space-y-6">
        <div className="relative">
          <div className="w-full flex flex-wrap text-sm font-medium text-center text-gray-500  border-gray-200 dark:border-gray-700 dark:text-gray-400">
            <div className="ml-8"></div>
            {primaryTabs.map((tab, index) => (
              <div
                onClick={() => setSelectedPrimaryTab(tab)}
                className={`rounded-t-lg border inline-block p-2 mr-2 cursor-pointer w-40  ${
                  selectedPrimaryTab === tab
                    ? 'bg-white border-b-0 z-40'
                    : 'bg-gray-100'
                } `}
                key={index}
              >
                <div
                  className={`${
                    selectedPrimaryTab === tab
                      ? 'text-gray-900'
                      : 'text-gray-500'
                  } text-sm font-semibold`}
                >
                  {tab}
                </div>
              </div>
            ))}
          </div>
          <div className="w-full h-[0.5px] absolute bottom-0 bg-gray-200"></div>
        </div>
        {selectedPrimaryTab === 'Plan Setup' && (
          <PlanSetUp
            eligibleUserData={eligibleUserData}
            setEligibleUserData={setEligibleUserData}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            eventSettings={eventSettings}
            setEventSettings={setEventSettings}
            tableData={tableData}
            setTableData={setTableData}
          />
        )}
      </div>
    </div>
  );
};
