import {
  AchievementMeasurementEnum,
  AttainmentDependencyEnum,
  AttainmentInputsEnum,
  CalculationFieldsEnum,
  ConfigureClawbackEnum,
  DependentFieldsEnum,
  EnableProrationEnum,
  PayoutAmountsEnum,
  RangeOrSpecificValueEnum,
  SlabsInclusionEnum,
  UserGroupConditionSpecificityEnum,
} from 'pages/variablePay/VariablePay.types';

export const payoutConfigurations = {
  payoutAmounts: {
    label: 'Payout Amounts',
    description: `Select how would you like to configure the payout amounts.`,
    options: [
      {
        id: PayoutAmountsEnum.PRECALCULATED,
        label: 'Pre-calculated Payout',
        description: `Amount(s) to be manually entered after calculating the payout(s) externally.`,
        value: PayoutAmountsEnum.PRECALCULATED,
      },
      {
        id: PayoutAmountsEnum.LOGIC_BASED_CALCULATION,
        label: 'Define Payout Logic',
        description: `Payout(s) will be defined based on the logic(s) entered in the system.`,
        value: PayoutAmountsEnum.LOGIC_BASED_CALCULATION,
      },
    ],
  },
  calculationFields: {
    label: 'Calculation Fields',
    description: `Select how the calculation of payouts will be determined.`,
    options: [
      {
        id: CalculationFieldsEnum.SYSTEM,
        label: 'System Fields',
        description: `The fields from the master data will be used.`,
        helpText: 'Ex: Tenure Duration, Date of Joining',
        value: CalculationFieldsEnum.SYSTEM,
      },
      {
        id: CalculationFieldsEnum.CUSTOM,
        label: 'Custom Fields',
        description: `Custom fields will be defined and used.`,
        helpText: 'Ex: Project Completion, Referral Date',
        value: CalculationFieldsEnum.CUSTOM,
      },
    ],
  },
};

export const achievementConfigurations = {
  attainmentInputs: {
    label: 'Attainment Inputs',
    description: `Select how would you like to input the attainment values`,
    options: [
      {
        id: AttainmentInputsEnum.DATE,
        label: 'Attainment Date',
        description: `Attainment dates will be entered.`,
        helpText: 'Ex: Date of Joining',
        value: AttainmentInputsEnum.DATE,
      },
      {
        id: AttainmentInputsEnum.DAYS,
        label: 'Attainment Days',
        description: `Days taken to attain will be entered.`,
        helpText: 'Ex: Days taken to complete the project',
        value: AttainmentInputsEnum.DAYS,
      },
      {
        id: AttainmentInputsEnum.PERCENTAGE,
        label: 'Attainment Percentage',
        description: `Attainment percentage(s) will be entered.`,
        helpText: 'Ex: Percentage of Project Completion',
        value: AttainmentInputsEnum.PERCENTAGE,
      },
      {
        id: AttainmentInputsEnum.TEXT,
        label: 'Attainment Text Input',
        description: `Text input(s) will be entered.`,
        helpText: 'Ex: Bonus Module launched',
        value: AttainmentInputsEnum.TEXT,
      },
    ],
  },
  attainmentDependency: {
    label: 'Attainment Dependency',
    description: `Select whether the attainment is dependent on another event or condition`,
    options: [
      {
        id: AttainmentDependencyEnum.INDEPENDENT,
        label: 'Independent Attainment',
        description: `Only this attainment triggers the bonus.`,
        helpText: 'Ex: Project Completion',
        value: AttainmentDependencyEnum.INDEPENDENT,
      },
      {
        id: AttainmentDependencyEnum.DEPENDENT,
        label: 'Dependent Attainment/Condition',
        description: `Attainment triggers when another condition is also met.`,
        helpText: 'Ex: Referee Completes 3 months',
        value: AttainmentDependencyEnum.DEPENDENT,
      },
    ],
  },
  dependentFields: {
    label: 'Dependent Fields',
    description: `Select how the conditions will be determined.`,
    options: [
      {
        id: `Dependent${DependentFieldsEnum.SYSTEM_FIELDS}`,
        label: 'System Fields',
        description: `The fields from the master data will be used.`,
        helpText: 'Ex: Service Duration, Employee Rating',
        value: DependentFieldsEnum.SYSTEM_FIELDS,
      },
      {
        id: `Dependent${DependentFieldsEnum.CUSTOM_FIELDS}`,
        label: 'Custom Fields',
        description: `Custom fields will be defined and used.`,
        helpText: 'Ex: Project Completion Percentage',
        value: DependentFieldsEnum.CUSTOM_FIELDS,
      },
    ],
  },
  dependentCriteria: {
    label: 'Dependent Criteria',
    description: `Define the criteria for dependency on another event or condition`,
    options: [],
  },
  achievementMeasurement: {
    label: 'Achievement Measurement',
    description: `Select how the achievements for various users will be measured.`,
    options: [
      {
        id: AchievementMeasurementEnum.ATTAINMENT_BASED,
        label: 'Based on Attainment(s)',
        description: `Only attainments of the users will be entered.`,
        helpText: 'Ex: Completion of Referee Tenure',
        value: AchievementMeasurementEnum.ATTAINMENT_BASED,
      },
      {
        id: AchievementMeasurementEnum.TARGET_BASED,
        label: 'Based on Target(s)',
        description: `Target(s) for the users will also be entered.`,
        helpText: 'Ex: Milestones to be acheived',
        value: AchievementMeasurementEnum.TARGET_BASED,
      },
    ],
  },
};

export const eventConfigurations = {
  slabsInclusion: {
    label: 'Slabs Inclusion',
    description: `Decide if the conditions will include slabs or levels.`,
    options: [
      {
        id: SlabsInclusionEnum.NO_SLABS,
        label: 'No Slabs',
        description: 'Conditions will be set without any slabs.',
        helpText: 'Example: A flat bonus for project completion',
        value: SlabsInclusionEnum.NO_SLABS,
      },
      {
        id: SlabsInclusionEnum.INCLUDES_SLABS,
        label: 'Includes Slabs',
        description: 'Conditions will include different slabs or levels',
        helpText: 'Ex: Tiers based on completion percentage',
        value: SlabsInclusionEnum.INCLUDES_SLABS,
      },
    ],
  },
  rangeOrSpecificValue: {
    label: 'Range or Specific Value',
    description:
      'Select whether the conditions will be defined within a range or as a specific value.',
    options: [
      {
        id: 1,
        label: 'Is in Specific Value',
        description: 'Conditions will be set as a specific value.',
        helpText: 'Ex: Completion of 100% of the project',
        value: RangeOrSpecificValueEnum.SPECIFIC_VALUE,
      },
      {
        id: 2,
        label: 'Is in Range(s)',
        description: 'Conditions will be set within specific ranges.',
        helpText: 'Ex: Completion of 50%-75% of the project',
        value: RangeOrSpecificValueEnum.RANGE_VALUE,
      },
    ],
  },
  userGroupConditionSpecificity: {
    label: 'User Group Condition Specificity',
    description: 'Define if conditions differ by user group.',
    options: [
      {
        id: 1,
        label: 'Same for All Users',
        description: 'The same conditions applies to all users.',
        helpText: 'Ex: Company-wide Project Completion',
        value: UserGroupConditionSpecificityEnum.SAME_FOR_ALL,
      },
      {
        id: 2,
        label: 'Differs by User Group',
        description: 'Different user will have different conditions.',
        helpText: 'Ex: Referrer Employee vs Referee Employee',
        value: UserGroupConditionSpecificityEnum.DIFFERS_BY_GROUP,
      },
    ],
  },
  enableProration: {
    label: 'Enable Proration',
    description: 'Configure whether the payout(s) will be based on proration.',
    options: [
      {
        id: 1,
        label: 'Configure Proration Based on Employee Data',
        description: 'Payout is prorated based employee data.',
        helpText: 'Ex: LOP Leaves, Employee Grade Change',
        value: EnableProrationEnum.BASED_ON_EMPLOYEE_DATA,
      },
      {
        id: 2,
        label: 'Configure Proration',
        description: 'Define proration conditions for payouts.',
        helpText: 'Ex: Prorated Quarterly',
        value: EnableProrationEnum.PRORATION_OCCURENCE,
      },
    ],
  },
  configureClawback: {
    label: 'Configure Clawback',
    description: 'Determine if payouts are subject to repayment or reversal.',
    options: [
      {
        id: 1,
        label: 'Configure Clawback',
        description: 'After a specified period following the event.',
        helpText: 'Ex: After X Days/Months of Joining',
        value: ConfigureClawbackEnum.CONFIGURE_CLAWBACK,
      },
      {
        id: 2,
        label: 'Configure Proration for Clawback',
        description: 'The clawback will be prorated.',
        helpText: 'Ex: Prorated Quarterly',
        value: ConfigureClawbackEnum.CONFIGURE_PRORATION_CLAWBACK,
      },
    ],
  },
};

export const managePlans = [
  {
    id: 1,
    planName: 'Plan 1',
    type: 'Event Based',
    status: 'Active',
    planStartDate: new Date(),
    planEndDate: new Date(),
    payoutFrequency: 'Monthly',
    lastModified: new Date(),
    payoutMonth: new Date(),
    totalTarget: 100000,
    totalPaid: 9090,
    paidStatus: 'Paid',
    cycleStartDate: new Date(),
    cycleEndDate: new Date(),
    employeeCount: 1000,
    received: 900,
    pending: 100,
  },
  {
    id: 2,
    planName: 'Plan 1',
    type: 'Event Based',
    status: 'Active',
    planStartDate: new Date(),
    planEndDate: new Date(),
    payoutFrequency: 'Monthly',
    lastModified: new Date(),
    payoutMonth: new Date(),
    totalTarget: 100000,
    totalPaid: 9090,
    paidStatus: 'Payout Pending',
    cycleStartDate: new Date(),
    cycleEndDate: new Date(),
    employeeCount: 1000,
    received: 900,
    pending: 100,
  },
];

export const planSetupTabs = [
  { id: 1, tabLabel: 'Settings' },
  { id: 2, tabLabel: 'Eligible Group' },
  { id: 3, tabLabel: 'Manage Data' },
  { id: 4, tabLabel: 'Payout Summary' },
];

export const payoutFrequencyDropdown = [
  { label: 'Annually', value: 'Annually' },
  { label: 'Bi-annually', value: 'Bi-annually' },
  { label: 'Quarterly', value: 'Quarterly' },
  { label: 'Monthly', value: 'Monthly' },
];
