import {
  ConfigurationKeysEnum,
  EventsSettings,
  PayoutAmountsEnum,
  RangeOrSpecificValueEnum,
  SlabsInclusionEnum,
  TargetFrequencyEnum,
  TargetInputsEnum,
  TargetModeEnum,
  UserGroupConditionSpecificityEnum,
  UserGroupPayoutSepecificityEnum,
} from 'pages/variablePay/VariablePay.types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Configuration } from '../Configuration';

type TargetConfigurationProps = {
  setEventSettings: Dispatch<SetStateAction<EventsSettings>>;
  eventSettings: EventsSettings;
  configuration: { [key: string]: any };
};

export const TargetConfiguration = ({
  eventSettings,
  setEventSettings,
  configuration,
}: TargetConfigurationProps) => {
  const [targetConfiguration, setTargetConfiguration] = useState(configuration);

  useEffect(() => {
    if (
      eventSettings?.vPayPayoutAmounts?.value ===
        PayoutAmountsEnum.LOGIC_BASED_CALCULATION &&
      !eventSettings?.vPaySlabsInclusion
    ) {
      setEventSettings({
        ...eventSettings,
        vPayTargetMode: { value: TargetModeEnum.IS_IN_PERCENTAGE },
        vPaySlabsInclusion: {
          value: SlabsInclusionEnum.NO_SLABS,
        },
        vPayRangeSpecificity: {
          value: RangeOrSpecificValueEnum.SPECIFIC_VALUE,
        },
        vPayUGConditionSpecificity: {
          value: UserGroupConditionSpecificityEnum.SAME_FOR_ALL,
        },
        vPayUGPayoutSpecificity: {
          value: UserGroupPayoutSepecificityEnum.SAME_PAYOUT_FOR_ALL,
        },
        vPayTargetFrequency: { value: TargetFrequencyEnum.STAYS_CONSTANT },
        vPayTargetInputs: { value: TargetInputsEnum.DEFINED_IN_SYSTEM },
      });
    }
  }, [eventSettings]);

  useEffect(() => {
    if (configuration) {
      if (
        eventSettings?.vPayPayoutAmounts?.value ===
        PayoutAmountsEnum.PRECALCULATED
      ) {
        setTargetConfiguration({
          ...configuration,
          children: configuration?.children?.filter(
            ({ key }: { key: ConfigurationKeysEnum }) =>
              key === ConfigurationKeysEnum.vPayEnableProration ||
              key === ConfigurationKeysEnum.vPayEnableClawback,
          ),
        });
      } else {
        setTargetConfiguration(configuration);
      }
    }
  }, [eventSettings, configuration]);

  const handleChange = (key: ConfigurationKeysEnum, value: string) => {
    const updatedSettings = eventSettings;
    if (value === PayoutAmountsEnum.PRECALCULATED) {
      delete updatedSettings?.vPayCalculationField;
      delete updatedSettings?.vPayAchievementMeasurement;
      delete updatedSettings?.vPayAttainmentInput;
      delete updatedSettings?.vPayAttainmentDependency;
      delete updatedSettings?.vPayDependentFields;
    }
    setEventSettings({ ...updatedSettings, [key]: { value } });
  };

  return (
    <Configuration
      eventSettings={eventSettings}
      setEventSettings={setEventSettings}
      configuration={targetConfiguration}
      handleChange={handleChange}
    />
  );
};
