import { EventsSettings } from 'pages/variablePay/VariablePay.types';
import { Dispatch, SetStateAction } from 'react';
import { Events } from './events/Events';

type SettingsProps = {
  setEventSettings: Dispatch<SetStateAction<EventsSettings>>;
  eventSettings: EventsSettings;
  setTableData: Dispatch<SetStateAction<any>>;
  tableData: any;
};

export const Settings = ({
  setEventSettings,
  eventSettings,
  tableData,
  setTableData,
}: SettingsProps) => {
  return (
    <Events
      setEventSettings={setEventSettings}
      eventSettings={eventSettings}
      tableData={tableData}
      setTableData={setTableData}
    />
  );
};
