import { AxiosError } from 'axios';
import * as CONSTANT from '../api.constant';
import { del, get, post } from '../apiCalls';

export const getUserGroups = async (
  pageIndex,
  pageSize,
  sortBy,
  searchWord,
) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/user-group/dynamic?pageSize=${pageSize}&pageIndex=${pageIndex}&sortOrder=${sortBy}&search=${searchWord}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! user group fetch failed');
    }
  }
};
export const getPermissionRoles = async (pageSize, pageIndex) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/rbac-new/roles/all?pageSize=${pageSize}&pageIndex=${pageIndex}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! user group fetch failed');
    }
  }
};
export const getRoleInfo = async (mode, id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/rbac-new/roles/get-info/${id}?mode=${mode}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! permission role by id fetch failed');
    }
  }
};
export const getUserGroupById = async (id, query, pageIndex, pageSize) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/user-group/dynamic/${id}${
        pageIndex !== null && pageIndex !== undefined
          ? `?pageIndex=${pageIndex}`
          : ''
      }${pageSize ? `&pageSize=${pageSize}` : ''}`,
    );

    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! user group data fetch failed');
    }
  }
};
export const getUserDetailsById = async (id, query, pageIndex, pageSize) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${
        CONSTANT.API_RBAC_V3
      }/user-group/dynamic/eligible-users/${id}${
        pageIndex !== null && pageIndex !== undefined
          ? `?pageIndex=${pageIndex}`
          : ''
      }${pageSize ? `&pageSize=${pageSize}` : ''}`,
    );

    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! user group data fetch failed');
    }
  }
};
export const updateDynamicUserGroup = async (data) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/user-group/dynamic/create-update`,
      data,
    );
    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.response.data.message);
    } else {
      throw new Error('Error! could not update/create dynamic user group');
    }
  }
};
export const getRoleRefresh = async (payload) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/roles/get-role-refresh`,
      payload,
    );
    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.response.data.message);
    } else {
      throw new Error('Error! could not refresh role');
    }
  }
};
export const addUpdateAccess = async (data) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/role-access/add`,
      data,
    );
    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.response.data.message);
    } else {
      throw new Error('Error! could not update/create role access');
    }
  }
};
/**
 * Fetches role access based on user group ID with pagination support.
 * @param {string} userGroupId - The ID of the user group.
 * @param {number} pageIndex - The index of the page to fetch.
 * @param {number} pageSize - The size of each page.
 * @return {Promise<Object>} A Promise that resolves with the fetched role access data.
 * @throws {AxiosError} If there is an Axios error during the request.
 * @throws {Error} If there is an error during the request.
 */
export const getRoleAccessByUserGroup = async (
  userGroupId,
  pageIndex,
  pageSize,
) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/user-group/dynamic/roles?`;
    if (userGroupId) {
      url += `&userGroupId=${userGroupId}`;
    }
    if (pageIndex) {
      url += `&pageIndex=${pageIndex}`;
    }
    if (pageSize) {
      url += `&pageSize=${pageSize}`;
    }
    const response = await get(url);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! role access fetch failed');
    }
  }
};
export const getRoleAccessById = async (roleId, accessId) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/role-access?roleId=${roleId}`;
    if (accessId) {
      url += `&roleAccessId=${accessId}`;
    }
    const response = await get(url);
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! role access fetch failed');
    }
  }
};
export const getRoleAccessList = async (mode, roleId, pageIndex, pageSize) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/rbac-new/roles/get-access/${roleId}?mode=${mode}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    const response = await get(url);
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! role access fetch failed');
    }
  }
};
export const createStaticUserGroup = async (data) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/user-group/static/create`,
      data,
    );
    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! could not create static user group');
    }
  }
};
export const deleteUserGroupById = async (id) => {
  try {
    const response = await del(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/user-group/dynamic/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! user group delete failed');
    }
  }
};
export const deleteRoleById = async (id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/rbac-new/roles/delete/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! role delete failed');
    }
  }
};
export const deleteAccessRole = async (id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/rbac-new/roles/delete-access-draft/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! role delete failed');
    }
  }
};
export const deleteRoleAccessById = async (id, roleId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/role-access/role-access-delete/${id}?roleId=${roleId}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! role access delete failed');
    }
  }
};
export const makeRoleDraft = async (roleId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/rbac-new/roles/create-draft/${roleId}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! role access delete failed');
    }
  }
};
export const publishRoleById = async (id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/rbac-new/roles/publish/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! role publish failed');
    }
  }
};
export const duplicateUserGroup = async (data) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/user-group/dynamic/duplicate-by-id`,
      data,
    );
    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! could not duplicate user group');
    }
  }
};
export const duplicateRoleById = async (id, name) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/roles/duplicate-role?id=${id}&name=${name}`,
      {},
    );
    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err);
    } else {
      throw new Error('Error! could not duplicate user group');
    }
  }
};
export const createPermissionRole = async (name) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/rbac-new/roles/create`,
      { name: name },
    );
    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err);
    } else {
      throw new Error('Error! could not duplicate user group');
    }
  }
};
export const createUpdatePermissionRole = async (data, type) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/roles/create-update/${type}`,
      data,
    );
    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err);
    } else {
      throw new Error('Error! could not create/update role');
    }
  }
};
export const saveInfoAsDraft = async (data, roleId) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/rbac-new/roles/draft-info/${roleId}`,
      data,
    );
    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err);
    } else {
      throw new Error('Error! could not create/update role');
    }
  }
};
export const createRoleAccess = async (roleId) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/rbac-new/roles/create-access-draft/${roleId}`,
    );
    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err);
    } else {
      throw new Error('Error! could not create/update role');
    }
  }
};

export const saveRoleAccessAsDraft = async (body, roleId) => {
  try {
    const res = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/rbac-new/roles/draft-access/${roleId}`,
      body,
    );
    return res;
  } catch (err) {
    if (err) {
      throw new AxiosError(err);
    } else {
      throw new Error('Error! could not create/update role');
    }
  }
};

export const getPermissionAccess = async (search) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/permission-sets?search=${search}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! permission access fetch failed');
    }
  }
};
export const getFieldLevelAccess = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/field-level-rbac/get-fields`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! field level access fetch failed');
    }
  }
};
export const getMasterAccess = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/master-row-level-rbac/get-master-rbac`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! master access fetch failed');
    }
  }
};
export const getMasterColumnsList = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/master/get-master-columns/${id}?dropdown=true`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! master columns list fetch failed');
    }
  }
};
export const getMasterAccessList = async (id, type) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/rbac-new/roles/get-master-table/${id}?mode=${type}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! master access list fetch failed');
    }
  }
};
export const getFieldLevelOptions = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/field-level-rbac/field-permissions`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! field level options fetch failed');
    }
  }
};
export const getAllPositionsDropdown = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/permission-sets/get-all-positions-dropdown`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! dropdown access fetch failed');
    }
  }
};
