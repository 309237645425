import { XMarkIcon } from '@heroicons/react/24/outline';
import { Input } from 'components/atoms/FormElements/input/Input';
import {
  ConfigurationKeysEnum,
  EventsSettings,
} from 'pages/variablePay/VariablePay.types';
import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

interface Tag {
  id: number;
  text: string;
}

type TagInputProps = {
  setEventSettings: Dispatch<SetStateAction<EventsSettings>>;
  eventSettings: EventsSettings;
  configKey: ConfigurationKeysEnum;
  optionKey: string;
};

const TagInput = ({
  eventSettings,
  setEventSettings,
  configKey,
  optionKey,
}: TagInputProps) => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    setEventSettings({
      ...eventSettings,
      [configKey]: {
        ...eventSettings?.[configKey as keyof EventsSettings],
        [optionKey]: {
          value: tags,
        },
      },
    });
  }, [tags]);

  useEffect(() => {
    setTags(
      eventSettings?.[configKey as keyof EventsSettings]?.[optionKey]?.value
        ? eventSettings?.[configKey as keyof EventsSettings]?.[optionKey]?.value
        : [],
    );
  }, []);

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      setTags([...tags, { id: Date.now(), text: inputValue }]);
      setInputValue('');
    }
  };

  const removeTag = (id: number) => {
    setTags(tags?.filter((tag) => tag?.id !== id));
  };

  return (
    <div className="border border-gray-300 p-[6px] rounded-lg flex flex-wrap items-center gap-2">
      {tags?.map((tag) => (
        <div
          key={tag?.id}
          className="flex items-center text-gray-700 py-[2px] px-1 rounded-md border border-gray-200"
        >
          <span className="text-sm font-medium">{tag?.text}</span>

          <XMarkIcon
            className="ml-2 cursor-pointer w-3 h-3 text-xs text-gray-400"
            onClick={() => removeTag(tag?.id)}
          />
        </div>
      ))}
      <div className="w-[58%]">
        <Input
          // @ts-ignore
          type="text"
          placeholder="Press Enter to add a tag"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          className="border-none focus:ring-0 text-sm w-full flex-grow !py-1 !px-0 focus:outline-none"
        />
      </div>
    </div>
  );
};

export default TagInput;
