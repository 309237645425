import { PlusIcon } from '@heroicons/react/24/solid';
import {
  CheckBoxProgram,
  Input,
  RadioButton,
} from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import FormulaBuilder from 'components/molecules/formulaBuilder/FormulaBuilder';
import {
  CalculationFieldsEnum,
  ConfigurationKeysEnum,
  EventsSettings,
  PayoutAmountsEnum,
} from 'pages/variablePay/VariablePay.types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { htmlParser } from 'utils/jsUtils';
import { Dependency } from './AchievementsConfiguration/Dependency';
import TagInput from './PayoutConfigurations/TagsInput';

type ConfigurationProps = {
  setEventSettings: Dispatch<SetStateAction<EventsSettings>>;
  eventSettings: EventsSettings;
  configuration: { [key: string]: any };
  handleChange?: (key: ConfigurationKeysEnum, value: PayoutAmountsEnum) => void;
};

export const Configuration = ({
  setEventSettings,
  eventSettings,
  configuration,
  handleChange,
}: ConfigurationProps) => {
  const handleChanges = (key: string, value: string) => {
    setEventSettings({ ...eventSettings, [key]: { value } });
  };
  const [formulaBuilderVisible, setFormulaBuilderVisible] = useState(false);
  const formulaDetails = {
    formulaString: '',
    displayFormula: '',
    dependencyValueMap: {},
    dependencyChainMap: {},
  };

  useEffect(() => {
    if (
      eventSettings.vPayPayoutAmounts?.value ===
        PayoutAmountsEnum.LOGIC_BASED_CALCULATION &&
      !eventSettings.vPayCalculationField
    ) {
      setEventSettings({
        ...eventSettings,
        vPayCalculationField: { value: CalculationFieldsEnum.SYSTEM },
      });
    }
  }, [eventSettings]);

  const handleMixedType = (
    value: any,
    otherItemIndex: number,
    optionKey: string,
    configKey: ConfigurationKeysEnum,
  ) => {
    setEventSettings({
      ...eventSettings,
      [configKey]: {
        ...eventSettings?.[configKey as keyof EventsSettings],
        [optionKey]: {
          value:
            otherItemIndex === 0
              ? [
                  eventSettings?.[configKey as keyof EventsSettings]?.[
                    optionKey
                  ]?.value?.[otherItemIndex],
                  value,
                ]
              : [
                  value,
                  eventSettings?.[configKey as keyof EventsSettings]?.[
                    optionKey
                  ]?.value?.[otherItemIndex],
                ],
        },
      },
    });
  };

  return (
    <div className="border border-gray-200 rounded-xl bg-white p-6 shadow-md space-y-6">
      <div className="border-b pb-2 border-gray-200">
        <p className="font-semibold text-gray-900">{configuration?.name}</p>
        <p className="text-sm text-gray-600">{configuration?.description}</p>
      </div>
      <div className="space-y-5">
        {configuration?.children?.map((config: any, index: number) => {
          return (
            <div key={config?.key} className="space-y-5">
              <div className="flex gap-16" key={config?.id}>
                <div className="w-1/5">
                  <p className="text-sm font-semibold text-gray-700">
                    {config?.name}
                  </p>
                  <p className="text-sm text-gray-600">{config?.description}</p>
                </div>
                {config?.children?.length > 0 && (
                  <div className="w-1/4 space-y-4">
                    {config?.children?.map((option: any) => {
                      return (
                        <div className="flex items-start" key={option?.id}>
                          {config?.dataType === 'Radio' && (
                            <RadioButton
                              // @ts-ignore
                              id={option?.key}
                              checked={
                                option?.key ===
                                eventSettings?.[
                                  config?.key as keyof EventsSettings
                                ]?.value
                              }
                              onChange={() =>
                                handleChange
                                  ? handleChange(config?.key, option?.key)
                                  : handleChanges(config?.key, option?.key)
                              }
                            />
                          )}
                          {config?.dataType === 'Checkbox' && (
                            <div className="ml-4 mr-3">
                              <CheckBoxProgram
                                //@ts-ignore
                                label=""
                                error=""
                                id={option?.key}
                                checked={eventSettings?.[
                                  config?.key as keyof EventsSettings
                                ]?.value?.includes(option?.key)}
                                icon=""
                                disabled={false}
                                onChange={() => {
                                  const currentValue =
                                    eventSettings?.[
                                      config?.key as keyof EventsSettings
                                    ]?.value || [];
                                  const updatedValue = currentValue.includes(
                                    option?.key,
                                  )
                                    ? currentValue.filter(
                                        (item: any) => item !== option?.key,
                                      )
                                    : [...currentValue, option?.key];

                                  setEventSettings({
                                    ...eventSettings,
                                    [config?.key as keyof EventsSettings]: {
                                      value: updatedValue,
                                    },
                                  });
                                }}
                              />
                            </div>
                          )}
                          <div className="-ml-1 space-y-2">
                            <div>
                              <p className="text-sm font-medium text-gray-900">
                                {option?.name}
                              </p>
                              <p className="text-gray-600 text-sm">
                                {htmlParser(option?.description)}
                              </p>
                            </div>
                            {option?.dataType === 'Tags' && (
                              <TagInput
                                setEventSettings={setEventSettings}
                                eventSettings={eventSettings}
                                configKey={config?.key}
                                optionKey={option?.key}
                              />
                            )}
                            {option?.dataType === 'Dropdown' && (
                              <Select
                                // @ts-ignore
                                options={option?.metadata?.options?.map(
                                  (item: string) => ({
                                    label: item,
                                    value: item,
                                  }),
                                )}
                                value={
                                  option?.metadata?.options
                                    ?.map((item: string) => ({
                                      label: item,
                                      value: item,
                                    }))
                                    ?.filter(
                                      (item: {
                                        label: string;
                                        value: string;
                                      }) =>
                                        item?.value ===
                                        eventSettings?.[
                                          config?.key as keyof EventsSettings
                                        ]?.[option?.key]?.value,
                                    )?.[0]
                                }
                                onChange={(item: any) =>
                                  setEventSettings({
                                    ...eventSettings,
                                    [config?.key]: {
                                      ...eventSettings?.[
                                        config?.key as keyof EventsSettings
                                      ],
                                      [option?.key]: { value: item?.value },
                                    },
                                  })
                                }
                              />
                            )}
                            {option?.dataType === 'Mixed' && (
                              <div className="flex items-center border border-gray-300 rounded-md pr-2 gap-2">
                                <Select
                                  // @ts-ignore
                                  id="remove-border-select"
                                  options={option?.metadata?.options?.map(
                                    (item: string) => ({
                                      label: item,
                                      value: item,
                                    }),
                                  )}
                                  value={
                                    option?.metadata?.options
                                      ?.filter(
                                        (item: string) =>
                                          item ===
                                          eventSettings?.[
                                            config?.key as keyof EventsSettings
                                          ]?.[option?.key]?.value?.[0],
                                      )
                                      ?.map((item: string) => ({
                                        label: item,
                                        value: item,
                                      }))?.[0]
                                  }
                                  onChange={(item: {
                                    label: string;
                                    value: string;
                                  }) =>
                                    handleMixedType(
                                      item?.value,
                                      1,
                                      option?.key,
                                      config?.key,
                                    )
                                  }
                                />
                                <div className="rounded bg-gray-50 border-y border-gray-200 flex items-center justify-center">
                                  <PlusIcon className="w-3 h-3 text-gray-600 m-0.5" />
                                </div>
                                {!formulaBuilderVisible && (
                                  <Input
                                    // @ts-ignore
                                    onClick={() =>
                                      setFormulaBuilderVisible(true)
                                    }
                                    className="!px-3 !py-2 !border-0"
                                    value={
                                      eventSettings?.[
                                        config?.key as keyof EventsSettings
                                      ]?.[option?.key]?.value?.[1]
                                        ?.displayFormula
                                    }
                                  />
                                )}
                                {formulaBuilderVisible && (
                                  <FormulaBuilder
                                    setModel={setFormulaBuilderVisible}
                                    saveAction={(formula: any) =>
                                      handleMixedType(
                                        formula,
                                        0,
                                        option?.key,
                                        config?.key,
                                      )
                                    }
                                    moduleName="Compensation"
                                    sourceId={undefined}
                                    selectedColumn={undefined}
                                    formulaDetails={
                                      eventSettings?.[
                                        config?.key as keyof EventsSettings
                                      ]?.[option?.key]?.value?.[1]
                                        ? eventSettings?.[
                                            config?.key as keyof EventsSettings
                                          ]?.[option?.key]?.value?.[1]
                                        : formulaDetails
                                    }
                                  />
                                )}
                                <span className="text-sm text-gray-500 border-r border-gray-200">
                                  {`(x)`}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {config?.children?.length === 0 && (
                  <div>
                    {config?.dataType === 'Condition' && (
                      <Dependency
                        eventSettings={eventSettings}
                        setEventSettings={setEventSettings}
                      />
                    )}
                  </div>
                )}
              </div>
              {index < configuration?.children?.length - 1 && (
                <div className="border-b border-gray-200"></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
