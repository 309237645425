import QueryMaker from 'components/molecules/QueryBuilder/QueryMaker';
import TemplateBuilder from 'pages/admin/templateBuilder/TemplateBuilder';
import EditLandingPage from 'pages/landingPageBuilder/EditLandingPage';
import LandingPage from 'pages/landingPageBuilder/LandingPage';
import PreviewLandingPage from 'pages/landingPageBuilder/PreviewLandingPage';
import AdminRoutes from 'routes/AdminRoutes/AdminRoutes';
import BenefitsRoutes from 'routes/BenefitsRoutes/BenefitsRoutes';
import CompensationRoutes from 'routes/CompensationRoutes/CompensationRoutes';
import EsopsRoutes from 'routes/EsopRoutes/EsopRoutes';
import HiringIntelligenceRoutes from 'routes/HiringIntelligenceRoutes/HiringIntelligenceRoutes';
import InsuranceRoutes from 'routes/InsuranceRoutes/InsuranceRoutes';
import LettersRoutes from 'routes/LettersRoutes/LettersRoutes';
import PolicyRoutes from 'routes/PolicyRoutes/PolicyRoutes';
import ProfileRoutes from 'routes/ProfileRoutes/ProfileRoutes';
import { RangeBuilderRoutes } from 'routes/RangeBuilderRoutes/RangeBuilderRoutes';
import RecognitionRoutes from 'routes/RecognitionRoutes/RecognitionRoutes';
import ReportBuilderRoutes from 'routes/ReportBuilderRoutes/ReportBuilderRoutes';
import SurfaceRoutes from 'routes/SurfaceRoutes/SurfaceRoutes';
import { TempRoutes } from 'routes/TempRoutes/tempRoutes'; // !warning: this needs to be removed after event
import UserTaskRoutes from 'routes/UserTaskRoutes/UserTaskRoutes';
import VariablePayRoutes from 'routes/VariablePayRoutes/VariablePayRoutes';

const routes = [
  ...ProfileRoutes,
  ...CompensationRoutes,
  ...SurfaceRoutes,
  ...BenefitsRoutes,
  ...PolicyRoutes,
  ...RecognitionRoutes,
  ...InsuranceRoutes,
  ...AdminRoutes,
  ...HiringIntelligenceRoutes,
  ...LettersRoutes,
  ...UserTaskRoutes,
  ...RangeBuilderRoutes,
  ...ReportBuilderRoutes,
  ...EsopsRoutes,
  ...TempRoutes,
  ...VariablePayRoutes,
  {
    name: 'landingPages',
    key: 'landingPages',
    parentId: null,
    module: 'Admin',
    route: '/qb',
    component: <QueryMaker />,
  },
  {
    name: 'landingPages',
    key: 'landingPages',
    parentId: null,
    module: 'Admin',
    route: '/landingPages',
    component: <LandingPage />,
  },
  {
    name: 'landingPages',
    key: 'landingPages',
    parentId: null,
    module: 'Admin',
    route: '/landingPages/create-new',
    component: <TemplateBuilder />,
  },
  {
    name: 'landingPages',
    key: 'landingPages',
    parentId: null,
    module: 'Admin',
    route: '/landingPages/edit/:id',
    component: <EditLandingPage />,
  },
  {
    name: 'landingPages',
    key: 'landingPages',
    parentId: null,
    module: 'Admin',
    route: '/landingPages/preview/:id',
    component: <PreviewLandingPage />,
  },
  {
    name: 'landingPages',
    key: 'landingPages',
    parentId: null,
    module: 'Admin',
    route: '/compensation/:id',
    component: <PreviewLandingPage />,
  },
  {
    name: 'landingPages',
    key: 'landingPages',
    parentId: null,
    module: 'Admin',
    route: '/redeem/:id',
    component: <PreviewLandingPage />,
  },
  {
    name: 'landingPages',
    key: 'landingPages',
    parentId: null,
    module: 'Admin',
    route: '/offer/:id',
    component: <PreviewLandingPage />,
  },
  {
    name: 'landingPages',
    key: 'landingPages',
    parentId: null,
    module: 'Admin',
    route: '/policies/:id',
    component: <PreviewLandingPage />,
  },
  {
    name: 'landingPages',
    key: 'landingPages',
    parentId: null,
    module: 'Admin',
    route: '/benefits/:id',
    component: <PreviewLandingPage />,
  },
  {
    name: 'landingPages',
    key: 'landingPages',
    parentId: null,
    module: 'Admin',
    route: '/insurance/:id',
    component: <PreviewLandingPage />,
  },
  {
    name: 'landingPages',
    key: 'landingPages',
    parentId: null,
    module: 'Admin',
    route: '/admin/:id',
    component: <PreviewLandingPage />,
  },
  {
    name: 'landingPages',
    key: 'landingPages',
    parentId: null,
    module: 'Admin',
    route: '/range-builder/plans/:id',
    component: <PreviewLandingPage />,
  },
  // {
  //   name: 'analytics-dashboard',
  //   key: 'dashboard',
  //   parentId: null,
  //   module: 'Admin',
  //   route: '/dashboard',
  //   component: <Dashboard />,
  // },
  // {
  //   name: 'analytics-dashboard',
  //   key: 'dashboard',
  //   parentId: null,
  //   module: 'Admin',
  //   route: '/compareCharts',
  //   component: <CompareCharts />,
  // },
];

export default routes;
