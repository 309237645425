import { getEligibleUserCount } from 'apiClient/benefit/benefits';
import { getPlanEligibility } from 'apiClient/variablePay/variablePay';
import FilterForEligibleUsers from 'pages/compensation/compPlanning/createPlanning/primaryTabs/planSetup/eligibleGroup/FilterForEligibleUsers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const EligibleGroup = ({
  setEligibilityPayload,
  eligibilityPayload,
}: any) => {
  const [eligibleUserData, setEligibleUserData] = useState<any>({});
  const [savedData, setSavedData] = useState<{ [key: string]: any }>({});
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const response = await getPlanEligibility(id);
        setEligibleUserData(response?.data);
        setSavedData((prev: any) => ({
          ...prev,
          criteriaInclusion: response?.data?.criteriaInclusion || {},
          criteriaExclusion: response?.data?.criteriaExclusion || {},
        }));
      } catch (error) {
        setSavedData((prev: any) => ({
          ...prev,
          criteriaInclusion: {},
          criteriaExclusion: {},
        }));
      }
    })();
  }, []);

  const fetchUserData = async () => {
    try {
      const body = {};
      // @ts-ignore
      body.query = {
        eligibilityRule: eligibleUserData?.criteriaInclusion || {},
        eligibilityExclusionRule: eligibleUserData?.criteriaExclusion || {},
      };
      const res = await getEligibleUserCount(body);
      return {
        data: res?.data,
        total: res?.total,
      };
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const body: any = {
      planId: id,
    };
    if (eligibleUserData) {
      body.criteriaInclusion = eligibleUserData?.criteriaInclusion;
      body.criteriaExclusion = eligibleUserData?.criteriaExclusion;
    }
    setEligibilityPayload(body);
  }, [eligibleUserData]);

  return (
    <div className="pt-6 px-8 bg-white">
      <FilterForEligibleUsers
        saveApiCall={(body: any) => setEligibleUserData(body)}
        fetchEligibleUser={fetchUserData}
        formatIndex={{}}
        savedData={savedData}
        selectedPrimaryTab=""
        userList={[]}
        valueStroreInParent={true}
        isLoading={false}
        title="Users"
        isSaving={false}
        planId={id}
        viewMode={false}
        downloadEligibleGroupUsers={undefined}
        hideBulkUpload={true}
        showSaveButton={false}
      />
    </div>
  );
};
