import { getConfig } from 'apiClient/variablePay/variablePay';
import {
  ConfigData,
  EventsSettings,
  PayoutAmountsEnum,
} from 'pages/variablePay/VariablePay.types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MetricsWeightage } from '../metrics/MetricsWeightage/MetricsWeightage';
import { PayoutCriteria } from '../metrics/PayoutCriteria/PayoutCriteria';
import { AchievementsConfiguration } from './AchievementsConfiguration/AchievementsConfiguration';
import { EventConfiguration } from './EventConfiguration/EventConfiguration';
import { PayoutCalculation } from './PayoutCalculation/PayoutCalculation';
import { PayoutConfiguration } from './PayoutConfigurations/PayoutConfiguration';
import { TargetConfiguration } from './TargetConfiguration/TargetConfiguration';

type EventsProps = {
  setEventSettings: Dispatch<SetStateAction<EventsSettings>>;
  eventSettings: EventsSettings;
  setTableData: Dispatch<SetStateAction<any>>;
  tableData: any;
};

export const Events = ({
  eventSettings,
  setEventSettings,
  tableData,
  setTableData,
}: EventsProps) => {
  const [configData, setConfigData] = useState<ConfigData>({
    PayoutConfiguration: [],
    AchievementsConfiguration: [],
  });
  const { state } = useLocation();

  useEffect(() => {
    (async () => {
      try {
        const response = await getConfig();
        setConfigData(response?.data);
      } catch (error) {
        setConfigData({});
      }
    })();
  }, []);

  return (
    <div className="pt-6 px-8 pb-6 space-y-5">
      <div className="pt-5 pb-6 px-6 bg-white rounded-xl shadow-md border border-gray-200 space-y-5">
        <p className="text-lg text-gray-900 font-semibold">
          {state?.bonusBased === 'Metrics' ? 'Metrics' : 'Events'}
        </p>
        <div className="border border-gray-200 rounded-xl bg-gray-50 p-5 space-y-4">
          <PayoutConfiguration
            eventSettings={eventSettings}
            setEventSettings={setEventSettings}
            configuration={
              configData?.PayoutConfiguration?.length
                ? configData?.PayoutConfiguration?.[0]
                : {}
            }
          />
          {eventSettings?.vPayPayoutAmounts?.value ===
            PayoutAmountsEnum.LOGIC_BASED_CALCULATION && (
            <AchievementsConfiguration
              eventSettings={eventSettings}
              setEventSettings={setEventSettings}
              configuration={
                configData?.AchievementsConfiguration?.length
                  ? configData?.AchievementsConfiguration?.[0]
                  : {}
              }
            />
          )}
          {state?.bonusBased !== 'Metrics' && (
            <EventConfiguration
              eventSettings={eventSettings}
              setEventSettings={setEventSettings}
              configuration={
                configData?.EventConfiguration?.length
                  ? configData?.EventConfiguration?.[0]
                  : {}
              }
            />
          )}
          {state?.bonusBased !== 'Events' && (
            <TargetConfiguration
              eventSettings={eventSettings}
              setEventSettings={setEventSettings}
              configuration={
                configData?.TargetConfiguration?.length
                  ? configData?.TargetConfiguration?.[0]
                  : {}
              }
            />
          )}
          {eventSettings?.vPayPayoutAmounts?.value ===
            PayoutAmountsEnum.LOGIC_BASED_CALCULATION && (
            <PayoutCalculation
              eventSettings={eventSettings}
              setTableData={setTableData}
              tableData={tableData}
            />
          )}
          {state?.bonusBased === 'Metrics' && <PayoutCriteria />}
        </div>
      </div>
      <div className="bg-white rounded-xl shadow-md space-y-5">
        {state?.bonusBased === 'Metrics' && <MetricsWeightage />}
      </div>
    </div>
  );
};
