import { get, post } from 'apiClient/apiCalls';
import { AxiosError } from 'axios';
import * as CONSTANT from '../api.constant';

export const createPlan = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_VPAY_V3}/api/plan/upsert-plan`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.response?.data?.message);
    } else {
      throw new Error('Error! Create plan failed');
    }
  }
};

export const createEligibility = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_VPAY_V3}/api/plan/eligibility/update`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.response?.data?.message);
    } else {
      throw new Error('Error! Create eligibility failed');
    }
  }
};

export const getConfig = async () => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_VPAY_V3}/api/plan-config/get-fields`,
      {
        sourceNames: [
          'PayoutConfiguration',
          'AchievementsConfiguration',
          'EventConfiguration',
          'TargetConfiguration',
        ],
      },
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.response?.data?.message);
    } else {
      throw new Error('Error! Create eligibility failed');
    }
  }
};

export const saveEvents = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_VPAY_V3}/api/plan-config/save`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.response?.data?.message);
    } else {
      throw new Error('Error! Create eligibility failed');
    }
  }
};

export const getPlanById = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_VPAY_V3}/api/plan-config/by-plan-id/${planId}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.response?.data?.message);
    } else {
      throw new Error('Error! Create eligibility failed');
    }
  }
};

export const getAllPlans = async (pageIndex) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_VPAY_V3}/api/plan/get-all?pageIndex=${pageIndex}&pageSize=5`,
      {},
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.response?.data?.message);
    } else {
      throw new Error('Error! Create fetch all plans failed!');
    }
  }
};

export const getPlanEligibility = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_VPAY_V3}/api/plan/eligibility/${planId}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.response?.data?.message);
    } else {
      throw new Error('Error! Create eligibility failed');
    }
  }
};

export const getPlanDetails = async (planId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_VPAY_V3}/api/plan/${planId}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.response?.data?.message);
    } else {
      throw new Error('Error! Create eligibility failed');
    }
  }
};
